import { Suspense, useEffect } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setIsMobile } from '@/redux/slices/settingsSlice'
import { checkAuth } from '@/redux/slices/candidateSlice'

import { Login } from '@/pages/Login'
import { AdminPanel } from '@/pages/AdminPanel'
import { UserPanel } from '@/pages/UserPanel'
import { Simulations } from '@/pages/Simulations'
import { Privacy } from '@/pages/Privacy'
import { ChatGame } from '@/pages/ChatGame'
import { Spinner } from '../ui/Spinner'

import '@/styles/app.scss'

const App = () => {
	const { auth, role } = useSelector(state => state.candidate)
	const dispatch = useDispatch()

	useEffect(() => {
		const handleResize = () => {
			dispatch(setIsMobile(window.innerWidth < 768))
		}

		handleResize()

		window.addEventListener('resize', handleResize)
		window.addEventListener('orientationchange', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
			window.removeEventListener('orientationchange', handleResize)
		}
	}, [dispatch])

	useEffect(() => {
		if (!auth) dispatch(checkAuth())
	}, [auth, dispatch])

	return (
		<Suspense fallback={<Spinner width={80} height={80} />}>
			<Routes>
				<Route path='/' element={<Navigate to='/login' />} />
				<Route path='/login' element={<Login />} />

				{auth && (
					<>
						<Route path='/simulations/*' element={<Simulations />} />
						<Route path='/chat-game/*' element={<ChatGame />} />

						{(role === 'Admin' || role === 'Manager') && (
							<Route path='/admin-panel/*' element={<AdminPanel />} />
						)}

						{role === 'Representative' && (
							<Route path='/user-panel/*' element={<UserPanel />} />
						)}
					</>
				)}

				<Route path='/privacy' element={<Privacy />} />
			</Routes>
		</Suspense>
	)
}

export default App
