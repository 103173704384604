import React, { useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { BorderArticle } from '@/components/ui/BorderArticle'
import styles from './styles.module.scss'
import { RootIcon } from '@/components/ui/icons/RootIcon'
import { RootDesc } from '@/components/ui/descriptions/RootDesc'
import { v4 as uuidv4 } from 'uuid'
import { ControlButton } from '@/components/ui/buttons/ControlButton'
import { SmallDesc } from '@/components/ui/descriptions/SmallDesc'
import { useDispatch } from 'react-redux'
import { setEditScenario } from '@/redux/slices/scenarioSlices/editScenarioSlice'
import { RootCheckbox } from '@/components/ui/inputs/RootCheckbox'

export const VideoViewLayout = () => {
	const location = useLocation()
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const video = location.state?.video

	const fieldList = useMemo(() => [
		{
			id: uuidv4(),
			type: 'products',
			name: 'Related Products',
			value: video.products,
		},
		{
			id: uuidv4(),
			type: 'purpose',
			name: 'Purpose of the Call',
			value: video.purpose,
		},
		{
			id: uuidv4(),
			type: 'objectives',
			name: 'Objectives of the Call',
			value: video.objectives,
		},
		{
			id: uuidv4(),
			type: 'focus',
			name: 'Focus of the Call',
			value: video.focus,
		},
		{
			id: uuidv4(),
			type: 'messages',
			name: 'Key Messages',
			value: video.messages,
		},
		{
			id: uuidv4(),
			type: 'structure',
			name: 'Call Structure',
			value: video.structure,
		},
		{
			id: uuidv4(),
			type: 'script',
			name: 'Call Script',
			value: video.script,
		},
		{
			id: uuidv4(),
			type: 'documents',
			name: 'Related Documents',
			value: video.documents,
		},
		{
			id: uuidv4(),
			type: 'questions',
			name: 'The most important questions for training:',
			value: video.questions,
		},
		{
			id: uuidv4(),
			type: 'created',
			name: 'Due Date',
			value: video.created,
		},
		{
			id: uuidv4(),
			type: 'status',
			name: 'Status',
			value: video.deactivate,
		},
	])

	const handleClickEdit = video => {
		dispatch(setEditScenario(video))

		navigate(`/admin-panel/scenarios/edit/${video.id}`, {
			state: { video },
		})
	}

	return (
		<div className={styles.videoWrapper}>
			<BorderArticle>
				<div className={styles.video}>
					<div className={styles.videoHead}>
						<button onClick={() => navigate(-1)}>
							<RootIcon width={17} height={17} id={'arrow-back'} />

							<RootDesc>
								<span>Back</span>
							</RootDesc>
						</button>
					</div>

					<div className={styles.videoContent}>
						<div className={styles.videoContentHead}>
							<div className='flex items-center gap-[16rem]'>
								<h2>{video.name}</h2>

								<ControlButton
									color='var(--blue)'
									txt={'Edit'}
									onClick={() => handleClickEdit(video)}
								/>
							</div>

							<RootDesc>
								<b>Cover Preview</b>
							</RootDesc>

							<BorderArticle>
								<div className={styles.videoPreview}>
									<div
										style={
											video.cover ? { background: `var(--${video.cover})` } : {}
										}
										className={styles.videoCover}
									></div>
									<img
										style={
											!video.preview ? { background: 'var(--greyLight)' } : {}
										}
										src={video.preview}
										alt='preview'
									/>
								</div>
							</BorderArticle>
						</div>

						<ul className={styles.videoContentBody}>
							{fieldList.map(field => (
								<li key={field.id}>
									<RootDesc>
										<b className='block w-[280rem]'>{video.name}</b>
									</RootDesc>

									{field.type === 'products' ? (
										<RootDesc>
											<span>{video.value.join(', ')}</span>
										</RootDesc>
									) : field.type === 'documents' ? (
										field.value.map(item => (
											<SmallDesc>
												<span className={styles.plateDocument}>{item}</span>
											</SmallDesc>
										))
									) : field.type === 'status' ? (
										<div className={styles.videoCheckbox}>
											<RootCheckbox
												fontSize={18}
												checked={field.value}
												onChange={e => console.log(' ')}
												label='Deactivate'
												disabled={true}
											/>
										</div>
									) : (
										<RootDesc>
											<span>{field.value}</span>
										</RootDesc>
									)}
								</li>
							))}
						</ul>
					</div>
				</div>
			</BorderArticle>
		</div>
	)
}
