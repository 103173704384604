import React from 'react'
import styles from './styles.module.scss'
import spinnerImage from '@/assets/images/root/spinner.svg'

export const Spinner = ({ width, height }) => {
	return (
		<div className={styles.spinnerWrapper}>
			<div
				style={
					width && height
						? { width: `${width}rem`, height: `${height}rem` }
						: { width: `120rem`, height: `120rem` }
				}
				className={styles.spinner}
			>
				<img src={spinnerImage} alt='spinner' />
			</div>
		</div>
	)
}
