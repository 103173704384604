import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import styles from './styles.module.scss'
import { RootDesc } from '@/components/ui/descriptions/RootDesc'
import { RootIcon } from '@/components/ui/icons/RootIcon'
import { SmallDesc } from '@/components/ui/descriptions/SmallDesc'
import clockImage from '@/assets/images/root/clock.svg'
import { VideoPopup } from '@/popups/VideoPopup'
import { Route, Routes, useNavigate } from 'react-router-dom'

export const VideosCardLayout = ({ video }) => {
	const [isPopupOpen, setIsPopupOpen] = useState(false)
	const navigate = useNavigate()

	const handleOpenPopup = id => {
		navigate(`video/${id}`)
		setIsPopupOpen(true)
	}

	const handleClosePopup = () => {
		navigate(-1)
		setIsPopupOpen(false)
	}

	return (
		<>
			<div key={video.id} className={styles.card}>
				<div
					style={
						video.link
							? { backgroundImage: `url(${video.image})` }
							: { background: 'var(--grey)' }
					}
					className={styles.cardPreview}
				>
					<ul className={styles.cardOptions}>
						<li>
							<SmallDesc>
								<span>{video.category}</span>
							</SmallDesc>
						</li>

						<li>
							<img src={clockImage} alt='clock' />
							<SmallDesc>
								<span>{video.time}</span>
							</SmallDesc>
						</li>
					</ul>

					<div
						onClick={() => handleOpenPopup(video.id)}
						className={styles.cardPlay}
					>
						<RootIcon id={'play'} />
					</div>
				</div>

				<div className={styles.cardContent}>
					<RootDesc>
						<b>{video.title}</b>
					</RootDesc>

					<RootDesc>
						<span className='text-[var(--grey)]'>{video.description}</span>
					</RootDesc>
				</div>
			</div>

			<Routes>
				<Route
					path='video/:id'
					element={
						<>
							{isPopupOpen &&
								ReactDOM.createPortal(
									<VideoPopup video={video} onClick={handleClosePopup} />,
									document.querySelector('body')
								)}
						</>
					}
				/>
			</Routes>
		</>
	)
}
