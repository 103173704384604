import { Link } from 'react-router-dom'
import { H3 } from '@/components/ui/titles/H3'
import { BorderArticle } from '@/components/ui/BorderArticle'
import { RootDesc } from '@/components/ui/descriptions/RootDesc'
import arrowImage from '@/assets/images/root/arrow-link.svg'
import activeImage from '@/assets/images/pages/dashboard/user.png'
import successImage from '@/assets/images/pages/dashboard/user-set.png'
import rejectImage from '@/assets/images/pages/dashboard/user-rem.png'
import moment from 'moment'

import styles from './Hello.module.scss'
import { useSelector } from 'react-redux'
import { StatisticCardsLayout } from '../../../components/layouts/StatisticCardsLayout'

export const Hello = () => {
	const overviewList = [
		{
			id: 0,
			icon: activeImage,
			title: 'Active Representative',
			total: '540',
			totalChange: '+5',
		},
		{
			id: 1,
			icon: successImage,
			title: 'Successfull Calls',
			total: '647',
			totalChange: '+25',
		},
		{
			id: 2,
			icon: rejectImage,
			title: 'Calls Rejections',
			total: '254',
			totalChange: '-16',
		},
	]

	const { name } = useSelector(state => state.candidate)

	return (
		<div className={styles.hello}>
			<div className={styles.helloHeader}>
				<h1>
					Hello, <b>{name}</b>
				</h1>

				<span>
					{moment().format('MMMM')}, <b>{moment().format('DD')}</b>
				</span>
			</div>

			<div className={styles.overview}>
				<div className={styles.overviewHead}>
					<H3>Overview</H3>

					<Link to={'/admin-panel/analytics'}>
						<RootDesc>
							<span>Analytics</span>
						</RootDesc>

						<div className={styles.chart}>
							<img src={arrowImage} alt='arrow' />
						</div>
					</Link>
				</div>

				<StatisticCardsLayout list={overviewList} />
			</div>
		</div>
	)
}
