import styles from './styles.module.scss'

import { BorderArticle } from '@/components/ui/BorderArticle'
import { Hello } from './Hello'
import { Calls } from './Calls'
import { World } from './World'
import { Activity } from './Activity'

export const Dashboard = () => {
	return (
		<div className='container-small'>
			<div className={styles.dashboard}>
				<div className={styles.helloWrapper}>
					<BorderArticle>
						<Hello />
					</BorderArticle>
				</div>

				<div className={styles.activityWrapper}>
					<BorderArticle>
						<Activity />
					</BorderArticle>
				</div>

				<BorderArticle>
					<Calls />
				</BorderArticle>

				<BorderArticle>
					<World />
				</BorderArticle>
			</div>
		</div>
	)
}
