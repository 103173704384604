import { useState, useRef, useEffect } from 'react'
import styles from './styles.module.scss'
import { RootDesc } from '../descriptions/RootDesc'
import { useNavigate } from 'react-router-dom'
import { RootIcon } from '../icons/RootIcon'
import { BorderArticle } from '../BorderArticle'

const DropdownMenu = props => {
	const { head, list, top, children } = props

	const [isOpen, setIsOpen] = useState(false)

	const dropdownRef = useRef(null)
	const navigate = useNavigate()

	const toggleDropdown = () => {
		setIsOpen(!isOpen)
	}

	const handleClickOutside = event => {
		if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
			setIsOpen(false)
		}
	}

	const handleClickMenuItem = link => {
		navigate(link)
		setIsOpen(false)
	}

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside)

		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [])

	return (
		<div className={styles.dropdown} ref={dropdownRef}>
			<div className={styles.dropdownHeader} onClick={toggleDropdown}>
				{head}

				<i
					style={
						isOpen
							? { transform: 'rotate(180deg)' }
							: { transform: 'rotate(0deg)' }
					}
				></i>
			</div>

			{isOpen && (
				<div style={{ top: `${top}rem` }} className={styles.dropdownList}>
					<BorderArticle>
						<ul>
							{list &&
								list.map(item => (
									<li
										key={item.id}
										onClick={() => handleClickMenuItem(item.link)}
									>
										<RootIcon id={item.iconId} />

										<RootDesc>
											<span>{item.name}</span>
										</RootDesc>
									</li>
								))}

							{children && <li>{children}</li>}
						</ul>
					</BorderArticle>
				</div>
			)}
		</div>
	)
}

export default DropdownMenu
