import React from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { BorderArticle } from '@/components/ui/BorderArticle'
import { Feedback } from './Feedback'
import { Statistic } from './Statistic'
import { UserViewLayout } from '../UserLayout/UserViewLayout'
import { Spinner } from '@/components/ui/Spinner'
import { UserEditLayout } from '../UserLayout/UserEditLayout'
import { ProfileLayout } from '../ProfileLayout'

import styles from './styles.module.scss'

export const ProfileStatisticLayout = () => {
	const location = useLocation()

	const { role } = useSelector(state => state.candidate)
	const { user, serverStatus } = useSelector(state => state.viewUser)
	const { editUser } = useSelector(state => state.editUser)

	return (
		<div className='container-small'>
			<div className={styles.profileWrapper}>
				{location.pathname.includes('/view/') && (
					<BorderArticle>
						<UserViewLayout />
					</BorderArticle>
				)}

				{location.pathname.includes('/edit/') && (
					<BorderArticle>
						<UserEditLayout />
					</BorderArticle>
				)}

				{!location.pathname.includes('/view/') &&
					!location.pathname.includes('/edit/') && (
						<BorderArticle>
							<ProfileLayout />
						</BorderArticle>
					)}

				{role === 'Admin' ? (
					serverStatus === 'loading' ? (
						<Spinner width={80} height={80} />
					) : (
						user?.role !== 'Admin' &&
						editUser?.role !== 'Admin' && (
							<BorderArticle>
								<Feedback />
							</BorderArticle>
						)
					)
				) : (
					!user &&
					!editUser && (
						<BorderArticle>
							<Feedback />
						</BorderArticle>
					)
				)}

				<BorderArticle>
					<Statistic />
				</BorderArticle>
			</div>
		</div>
	)
}
