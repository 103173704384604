import React from 'react'

import { BorderArticle } from '@/components/ui/BorderArticle'
import { H3 } from '@/components/ui/titles/H3'
import { PreviewCard } from '@/components/ui/PreviewCard'

import styles from './styles.module.scss'

export const SimulationsLayout = ({ cards, title }) => {
	return (
		<div className='container-big phone:p-0'>
			<div className={styles.simulationsWrapper}>
				<BorderArticle>
					<div className={styles.simulations}>
						<H3>{title}</H3>

						<ul className={styles.simulationsList}>
							{cards &&
								cards.map(item => (
									<li key={item.id}>
										<PreviewCard
											levelList={true}
											name={item?.name}
											image={item?.cover}
											until={item?.until}
											mask={item?.mask}
											id={item?.id}
										/>
									</li>
								))}
						</ul>
					</div>
				</BorderArticle>
			</div>
		</div>
	)
}
