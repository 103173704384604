import { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
	Configuration,
	NewSessionData,
	StreamingAvatarApi,
} from '@heygen/streaming-avatar'
import {
	getToken,
	clearAvatarState,
	setChatTime,
	createSession,
} from '@/redux/slices/avatarSlices/avatarSlice'

import { Spinner } from '@/components/ui/Spinner'
import { LevelPopup } from '@/popups/simulationPopups/LevelPopup'
import { AiFeedbackPopup } from '@/popups/simulationPopups/AiFeedbackPopup'
import { usePopup } from '@/components/layouts/InfoPopup/PopupContext'
import { RootIcon } from '@/components/ui/icons/RootIcon'
import { RootDesc } from '@/components/ui/descriptions/RootDesc'
import { H3 } from '@/components/ui/titles/H3'
import { AvatarErrorPopup } from '@/popups/simulationPopups/AvatarErrorPopup'

import styles from './styles.module.scss'

function Avatar() {
	const { openPopup, closePopup } = usePopup()
	const { isMobile } = useSelector(state => state.settings)

	const [loadingStream, setLoadingStream] = useState(true)
	const [stream, setStream] = useState<MediaStream | null>(null)
	const [data, setData] = useState<NewSessionData | null>(null)
	const avatar = useRef<StreamingAvatarApi | null>(null)
	const mediaStream = useRef<HTMLVideoElement | null>(null)

	const [debug, setDebug] = useState<string>('')

	const {
		messages,
		avatarId,
		avatarToken,
		volume,
		botMessage,
		chatTime,
		score,
		session_id,
		scenario_id,
		error,
	} = useSelector(state => state.avatar)

	const dispatch = useDispatch()
	const navigate = useNavigate()
	const location = useLocation()
	const level = location.state?.level

	const handleCloseLevelPopup = () => {
		setLoadingStream(true)
		start()
		closePopup()
	}

	const handleTryAgain = async () => {
		closePopup()

		await dispatch(createSession({ level: level.name, scenario_id }))
		await stop()
		await initializeAvatar()
		await start()
	}

	const handleQuit = async () => {
		closePopup()

		await stop()
		dispatch(clearAvatarState())
		navigate('/simulations')
	}

	const start = async () => {
		if (!avatar.current) return

		try {
			const res = await avatar.current.createStartAvatar(
				{
					newSessionRequest: {
						quality: 'high',
						avatarName: avatarId,
					},
				},
				setDebug
			)

			setData(res)
			setStream(avatar.current.mediaStream)
			setLoadingStream(false)
			dispatch(setChatTime(level.remainingTime))
		} catch (error) {
			openPopup(
				<AvatarErrorPopup
					onClickTryAgain={handleTryAgain}
					onClickExit={handleQuit}
				/>,
				{ closeButton: false }
			)
		}
	}

	const stop = async () => {
		if (avatar.current) {
			await avatar.current.stopAvatar(
				{ stopSessionRequest: { sessionId: data?.sessionId } },
				setDebug
			)
		}

		if (stream) {
			stream.getTracks().forEach(track => track.stop())
			setStream(null)
		}

		setLoadingStream(true)
		setStream(null)
		setDebug('')
		setData(null)

		dispatch(clearAvatarState())
	}

	const handleSpeak = async (msg: string) => {
		if (avatar.current) {
			try {
				await avatar.current.speak({
					taskRequest: { text: msg, sessionId: data?.sessionId },
				})
			} catch (e) {
				setDebug(e.message)
			}
		}
	}

	const getAvatarToken = async () => {
		const resultAction = await dispatch(getToken(session_id))

		return getToken.fulfilled.match(resultAction)
	}

	const startTalkCallback = (e: any) => {
		console.log('Avatar started talking', e)
	}

	const stopTalkCallback = (e: any) => {
		console.log('Avatar stopped talking', e)
	}

	const initializeAvatar = async () => {
		try {
			const tokenFetched = await getAvatarToken()

			if (tokenFetched) {
				avatar.current = new StreamingAvatarApi(
					new Configuration({ accessToken: avatarToken })
				)

				avatar.current.addEventHandler(
					'avatar_start_talking',
					startTalkCallback
				)

				avatar.current.addEventHandler('avatar_stop_talking', stopTalkCallback)
			} else {
				navigate(-1)
			}
		} catch (error) {
			openPopup(
				<AvatarErrorPopup
					onClickTryAgain={handleTryAgain}
					onClickExit={handleQuit}
				/>,
				{ closeButton: false }
			)

			setDebug(error.message)
		}
	}

	const handleClickQuitBtn = async () => {
		if (messages.length > 0 && chatTime > 0) {
			openPopup(<AiFeedbackPopup again={handleTryAgain} quit={handleQuit} />, {
				closeButton: false,
			})
		} else {
			navigate(-1)
		}
	}

	useEffect(() => {
		if (!avatar.current) {
			initializeAvatar()
		}

		return () => {
			if (avatar.current) {
				avatar.current.removeEventHandler(
					'avatar_start_talking',
					startTalkCallback
				)

				avatar.current.removeEventHandler(
					'avatar_stop_talking',
					stopTalkCallback
				)
			}
		}
	}, [avatarToken])

	useEffect(() => {
		if (stream && mediaStream.current) {
			mediaStream.current.srcObject = stream

			mediaStream.current.onloadedmetadata = () => {
				mediaStream.current.play()
				setDebug('Playing')
			}
		}
	}, [stream])

	useEffect(() => {
		if (mediaStream.current) {
			mediaStream.current.volume = volume
		}
	}, [volume])

	useEffect(() => {
		if (botMessage) {
			handleSpeak(botMessage)
		}
	}, [botMessage])

	useEffect(() => {
		if (chatTime) {
			let remainingTime = chatTime

			const timer = setInterval(() => {
				if (remainingTime <= 1000 || score === 0) {
					clearInterval(timer)

					openPopup(
						<AiFeedbackPopup again={handleTryAgain} quit={handleQuit} />,
						{ closeButton: false }
					)
				} else {
					remainingTime -= 1000

					dispatch(setChatTime(remainingTime))
				}
			}, 1000)

			return () => clearInterval(timer)
		}
	}, [chatTime, score])

	useEffect(() => {
		openPopup(<LevelPopup close={handleCloseLevelPopup} item={level} />, {
			closeButton: false,
		})

		return () => {
			stop()
			closePopup()
		}
	}, [])

	return (
		<>
			<div className={styles.avatarWrapper}>
				<div className={styles.avatarPlayer}>
					{loadingStream || !stream || !data ? (
						<Spinner width={80} height={80} />
					) : error !== '' ? (
						<H3>
							Failed to load avatar! Contact our administrator or support.
						</H3>
					) : (
						<video
							controls={false}
							playsInline
							autoPlay
							ref={mediaStream}
						></video>
					)}
				</div>
			</div>

			<div onClick={handleClickQuitBtn} className={styles.quitBtn}>
				<i>
					<RootIcon width={32} height={32} id={'quit'} />
				</i>

				{!isMobile && (
					<RootDesc>
						<span>End the Visit</span>
					</RootDesc>
				)}
			</div>
		</>
	)
}

export default Avatar
