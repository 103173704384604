import { useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
	clearScenarios,
	getScenarios,
	setPage,
	setSize,
	setSort,
	setTotalPages,
} from '@/redux/slices/scenarioSlices/scenariosSlice'

import moment from 'moment'

import TableLayout from '@/components/layouts/TableLayout'
import { ScreenLayout } from '@/components/layouts/ScreenLayout'
import { ControlButton } from '@/components/ui/buttons/ControlButton'
import { RootCheckbox } from '@/components/ui/inputs/RootCheckbox'
import { TableMobileLayout } from '@/components/layouts/TableMobileLayout'

export const Scenarios = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const { isMobile } = useSelector(state => state.settings)
	const { scenarios, page, sort, size, totalPages, serverStatus, error } =
		useSelector(state => state.scenarios)

	const handleClickAction = async (scenario, action) => {
		const id = scenario.id

		if (action === 'view') {
			navigate(`view/${id}`, { state: { id } })
		} else if (action === 'edit') {
			navigate(`edit/${id}`, { state: { id } })
		}
	}

	const handleClickAddNew = () => {
		navigate('create')
	}

	const handleClickDeactivate = scenario => {
		console.log('Deactivate product with id: ', scenario.id)
	}

	const handleClickDeactivateAll = () => {
		console.log('Deactivated check products!')
	}

	const handleCheckFilter = () => {
		console.log('Show inactive products!')
	}

	const goToNextPage = () => {
		if (page < totalPages) {
			dispatch(setPage(page + 1))
		} else return
	}

	const goToPreviousPage = () => {
		if (page > 1) {
			dispatch(setPage(page - 1))
		} else return
	}

	const goToPage = pageIndex => {
		dispatch(setPage(pageIndex + 1))
	}

	const handleSelectSize = option => {
		dispatch(setSize(+option))
		dispatch(setPage(1))
		dispatch(setTotalPages(Math.ceil(totalPages / size)))
	}

	const handleSelectSort = option => {
		dispatch(setSort(option))
	}

	const controls = [
		{
			id: 0,
			button: (
				<ControlButton
					color='var(--green)'
					txt={'Add New'}
					onClick={() => handleClickAddNew()}
				/>
			),
		},
		{
			id: 1,
			button: (
				<ControlButton
					color='var(--red)'
					txt={'Deactivate'}
					onClick={() => handleClickDeactivateAll()}
				/>
			),
		},
	]

	const columns = useMemo(
		() => [
			{
				id: 'selection',
				Header: ({ getToggleAllRowsSelectedProps }) => (
					<div>
						<RootCheckbox {...getToggleAllRowsSelectedProps()} />
					</div>
				),
				Cell: ({ row }) => (
					<div>
						<RootCheckbox {...row.getToggleRowSelectedProps()} />
					</div>
				),
				width: 35,
			},
			{ Header: 'Name', accessor: 'name', width: 235 },
			{ Header: 'Products', accessor: 'products', width: 250 },
			{
				Header: 'Created At',
				accessor: 'created_at',
				Cell: ({ cell: { value } }) => {
					const formattedDate = moment(value).format('YYYY-MM-DD')

					return <span>{formattedDate}</span>
				},
				width: 250,
			},
			{ Header: 'Status', Cell: () => <>Created</>, width: 415 },
			{
				Header: 'Actions',
				Cell: ({ row }) => (
					<div className='flex gap-[8rem]'>
						<ControlButton
							color='var(--orange)'
							txt={'View'}
							onClick={() => handleClickAction(row.original, 'view')}
						/>
						<ControlButton
							color='var(--blueLight)'
							txt={'Edit'}
							onClick={() => handleClickAction(row.original, 'edit')}
						/>
						<ControlButton
							color='var(--red)'
							txt={'Deactivate'}
							onClick={() => handleClickDeactivate(row.original)}
						/>
					</div>
				),
				width: 285,
			},
		],
		[]
	)

	useEffect(() => {
		dispatch(getScenarios({ page, size }))
	}, [page, size])

	useEffect(() => {
		return () => {
			dispatch(clearScenarios())
		}
	}, [])

	return (
		<ScreenLayout controlsList={controls} title={'Scenarios'}>
			{!isMobile ? (
				<TableLayout
					serverStatus={serverStatus}
					serverError={error}
					nextPage={goToNextPage}
					previousPage={goToPreviousPage}
					totalPages={totalPages}
					dataSize={size}
					currentPage={page}
					toPage={goToPage}
					selectSize={handleSelectSize}
					columns={columns}
					data={scenarios}
					filterCheckbox={
						<RootCheckbox
							onChange={() => handleCheckFilter()}
							label={'Show Inactive Scenarios'}
						/>
					}
				/>
			) : (
				<TableMobileLayout
					totalPages={totalPages}
					toPage={goToPage}
					columns={columns}
					data={scenarios}
					dataSort={sort}
					selectSort={handleSelectSort}
					actions={scenarios.map(scenario => (
						<div key={scenario.id} className='flex gap-[8rem]'>
							<ControlButton
								color='var(--orange)'
								txt={'View'}
								onClick={() => handleClickAction(scenario, 'view')}
							/>

							<ControlButton
								color='var(--blueLight)'
								txt={'Edit'}
								onClick={() => handleClickAction(scenario, 'edit')}
							/>

							<ControlButton
								color='var(--red)'
								txt={'Deactivate'}
								onClick={() => handleClickDeactivate(scenario)}
							/>
						</div>
					))}
				/>
			)}
		</ScreenLayout>
	)
}
