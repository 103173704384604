import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
	clearViewSession,
	viewSession,
} from '@/redux/slices/sessionSlices/viewSessionSlice'

import { BorderArticle } from '@/components/ui/BorderArticle'
import { RootDesc } from '@/components/ui/descriptions/RootDesc'
import { BackButton } from '@/components/ui/buttons/BackButton'
import { Spinner } from '@/components/ui/Spinner'

import styles from './styles.module.scss'

const SessionField = ({ label, value }) => {
	return (
		<li>
			<RootDesc>
				<b className='block w-[150rem]'>{label}</b>
			</RootDesc>

			<RootDesc>
				<span>{value}</span>
			</RootDesc>
		</li>
	)
}

export const SessionViewLayout = () => {
	const dispatch = useDispatch()

	const id = useParams().id

	const { session, serverStatus } = useSelector(state => state.viewSession)

	useEffect(() => {
		dispatch(viewSession({ id }))

		return () => {
			dispatch(clearViewSession())
		}
	}, [dispatch])

	return (
		<div className={styles.sessionWrapper}>
			<BorderArticle>
				<div className={styles.session}>
					<BackButton />

					{serverStatus === 'loading' ? (
						<Spinner width={80} height={80} />
					) : (
						<div className={styles.sessionContent}>
							<div className={styles.sessionContentHead}>
								<h2>{session?.created_at}</h2>
							</div>

							<ul className={styles.sessionContentBody}>
								<SessionField label={'Scanario'} value={session?.name} />

								<SessionField label={'User'} value={session?.user} />

								<SessionField label={'Products'} value={session?.products} />

								<SessionField label={'Level'} value={session?.level} />

								<SessionField label={'Status'} value={session?.status} />

								<SessionField label={'AI Feedback'} value={session?.feedback} />

								<SessionField
									label={'Dialog Details'}
									value={session?.details}
								/>
							</ul>
						</div>
					)}
				</div>
			</BorderArticle>
		</div>
	)
}
