import { BorderArticle } from '@/components/ui/BorderArticle'
import styles from './styles.module.scss'

import closeImg from '@/assets/images/popups/close.svg'
import { useSelector } from 'react-redux'

export const RootPopup = props => {
	const { width, children, close, onClick } = props
	const { isMobile } = useSelector(state => state.settings)

	return (
		<BorderArticle>
			<div
				className={styles.rootPopup}
				style={isMobile ? { maxWidth: '100%' } : { maxWidth: `${width}rem` }}
			>
				{close && (
					<div onClick={onClick} className={styles.close}>
						<img src={closeImg} alt='close' />
					</div>
				)}

				{children}
			</div>
		</BorderArticle>
	)
}
