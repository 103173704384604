import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
	setIsProductEdit,
	setEditProduct,
	getEditProduct,
	clearEditProduct,
	setError,
	updateProduct,
} from '@/redux/slices/productSlices/editProductSlice'
import {
	getCountries,
	getProductLine,
	clearProductOptions,
} from '@/redux/slices/productSlices/productOptionsSlice'

import { isEqual, levelOrder, capitalize } from '@/helpers'
import { usePopup } from '../InfoPopup/PopupContext'
import { BorderArticle } from '@/components/ui/BorderArticle'
import { RootDesc } from '@/components/ui/descriptions/RootDesc'
import { ControlButton } from '@/components/ui/buttons/ControlButton'
import { EditInput } from '@/components/ui/inputs/EditInput'
import EditSelect from '@/components/ui/inputs/EditSelect'
import { UploadImagePopup } from '@/popups/uploadPopups/UploadImagePopup'
import { RootTextarea } from '@/components/ui/inputs/RootTextarea'
import { BackButton } from '@/components/ui/buttons/BackButton'
import { MaskList } from '@/components/ui/MaskList'
import { Spinner } from '@/components/ui/Spinner'
import { DocumentPreview } from '@/components/ui/DocumentPreview'
import { FillDataPopup } from '@/popups/attentionPopups/FillDataPopup'
import { SuccessfulSavePopup } from '@/popups/attentionPopups/SuccessfulSavePopup'
import { SmallDesc } from '@/components/ui/descriptions/SmallDesc'
import { PreviewCard } from '@/components/ui/PreviewCard'

import styles from './styles.module.scss'

const ProductLevelDocuments = ({ level, files }) => (
	<div className={styles.productLevel}>
		<RootDesc>
			<b className='block w-[150rem]'>{capitalize(level)}</b>
		</RootDesc>

		<div className={styles.productDocuments}>
			{files.map(file => (
				<div key={file.id}>
					<SmallDesc>
						<span className={styles.plateDocument}>
							{file.metadata?.filename}
						</span>
					</SmallDesc>
				</div>
			))}
		</div>
	</div>
)

export const ProductEditLayout = () => {
	const { openPopup } = usePopup()

	const id = useParams().id
	const dispatch = useDispatch()

	const { isMobile } = useSelector(state => state.settings)
	const { countries, productLine } = useSelector(state => state.productOptions)
	const { editProduct, initialEditProduct, serverStatus } = useSelector(
		state => state.editProduct
	)

	const [groupedFiles, setGroupedFiles] = useState([])
	const [photoFile, setPhotoFile] = useState(null)

	const updateProductField = useCallback(
		(fieldType, value) => {
			dispatch(
				setEditProduct({
					...editProduct,
					product: {
						...editProduct.product,
						[fieldType]: value,
					},
				})
			)
		},
		[editProduct, dispatch]
	)

	const updateProductVersionField = useCallback(
		(fieldType, value) => {
			dispatch(
				setEditProduct({
					...editProduct,
					product_version: {
						...editProduct.product_version,
						[fieldType]: value,
					},
				})
			)
		},
		[editProduct, dispatch]
	)

	const handleClickCover = useCallback(
		color => updateProductField('mask', color),
		[updateProductField]
	)

	const handleClickRemovePhoto = () => {
		setPhotoFile(null)
		updateProductField('cover', null)
	}

	const handleClickUploadImage = () => {
		openPopup(<UploadImagePopup setPhotoFile={setPhotoFile} />)
	}

	const handleClickSave = async () => {
		if (
			!editProduct.product.mask ||
			!editProduct.product.name ||
			!editProduct.product.country_id ||
			!editProduct.product.product_line_id
		) {
			openPopup(<FillDataPopup />)
		} else {
			const product = {
				product: {
					name: editProduct.product.name,
					country_id: editProduct.product.country_id,
					product_line_id: editProduct.product.product_line_id,
					cover: photoFile,
					mask: editProduct.product.mask,
				},
				product_version: {
					questions: editProduct.product_version.questions,
				},
			}

			const resultAction = await dispatch(updateProduct({ id, product }))

			if (updateProduct.fulfilled.match(resultAction)) {
				openPopup(<SuccessfulSavePopup title={'product'} />)
			} else {
				dispatch(setError('Save error!'))
			}
		}
	}

	useEffect(() => {
		if (editProduct?.product_version?.files) {
			const filesByLevel = levelOrder.map(level => ({
				level,
				file: editProduct.product_version.files.filter(
					file => file.level === level
				),
			}))

			setGroupedFiles(filesByLevel)
		}
	}, [editProduct])

	useEffect(() => {
		if (editProduct && initialEditProduct) {
			dispatch(setIsProductEdit(!isEqual(editProduct, initialEditProduct)))
		}
	}, [editProduct, initialEditProduct, dispatch])

	useEffect(() => {
		dispatch(getEditProduct({ id }))
		dispatch(getCountries())
		dispatch(getProductLine())

		return () => {
			dispatch(clearEditProduct())
			dispatch(clearProductOptions())
		}
	}, [dispatch])

	return (
		<div className={styles.productWrapper}>
			<BorderArticle>
				<div className={styles.product}>
					<BackButton />

					{serverStatus === 'loading' ? (
						<Spinner width={80} height={80} />
					) : (
						<div className={styles.productContent}>
							<div className={styles.productContentHeadWrapper}>
								<div className={styles.productEditContent}>
									<div className={styles.productContentHead}>
										<div className='flex items-center gap-[16rem]'>
											<EditInput
												width={!isMobile ? 350 : 210}
												placeholder={`Product name*`}
												label='product-name'
												type='text'
												value={editProduct?.product?.name}
												onChange={e =>
													updateProductField('name', e.target.value)
												}
											/>

											<ControlButton
												color='var(--green)'
												txt={'Save'}
												onClick={() => handleClickSave()}
											/>
										</div>

										<div className='flex items-start gap-[16rem] phone:flex-col'>
											<RootDesc>
												<b className='block w-[150rem]'>
													Choose Background<sup>*</sup>
												</b>
											</RootDesc>

											<MaskList
												onClickCover={color => handleClickCover(color)}
											/>
										</div>

										<div className='flex items-start gap-[16rem] phone:flex-col'>
											<RootDesc>
												<b className='block w-[150rem]'>Cover</b>
											</RootDesc>

											<div className='flex flex-col items-start gap-[16rem]'>
												{(editProduct?.product?.cover || photoFile) && (
													<DocumentPreview
														name={
															editProduct?.product?.cover_metadata.filename ||
															photoFile.name
														}
														onClickCross={() => handleClickRemovePhoto()}
													/>
												)}

												<ControlButton
													color='var(--violetLight)'
													txt={'Upload Image'}
													onClick={handleClickUploadImage}
												/>
											</div>
										</div>
									</div>

									<div className='flex flex-col gap-[24rem] items-start'>
										<RootDesc>
											<b>Cover Preview</b>
										</RootDesc>

										<BorderArticle>
											<PreviewCard
												name={editProduct?.product?.name}
												mask={editProduct?.product?.mask}
												image={
													photoFile
														? URL.createObjectURL(photoFile)
														: editProduct?.product?.cover || undefined
												}
											/>
										</BorderArticle>
									</div>
								</div>
							</div>

							<ul className={`${styles.productContentBody} ${styles.editBody}`}>
								<li>
									<RootDesc>
										<b className='block w-[280rem]'>
											Country<sup>*</sup>
										</b>
									</RootDesc>

									{countries.length > 0 && (
										<div className='w-[240rem] phone:w-[100%]'>
											<EditSelect
												searchPlaceholder='Search Country'
												activeOption={editProduct?.product?.country}
												options={countries}
												onSelect={selectedOption =>
													updateProductField('country_id', selectedOption.id)
												}
											/>
										</div>
									)}
								</li>

								<li>
									<RootDesc>
										<b className='block w-[280rem]'>
											Product Line<sup>*</sup>
										</b>
									</RootDesc>

									{productLine.length > 0 && (
										<div className='w-[240rem] phone:w-[100%]'>
											<EditSelect
												searchPlaceholder='Search Product Line'
												activeOption={editProduct?.product?.product_line}
												options={productLine}
												onSelect={selectedOption =>
													updateProductField(
														'product_line_id',
														selectedOption.id
													)
												}
											/>
										</div>
									)}
								</li>

								<li>
									<RootDesc>
										<b className='block w-[280rem] phone:w-[100%]'>
											Related Documents by Difficulty Level
										</b>
									</RootDesc>

									<div className={styles.productLevelList}>
										{groupedFiles.map(item => (
											<ProductLevelDocuments
												key={item.level}
												level={item.level}
												files={item.file}
											/>
										))}
									</div>
								</li>

								<li>
									<RootDesc>
										<b className='block w-[280rem] phone:w-[100%]'>
											The most important questions for training:
										</b>
									</RootDesc>

									<RootTextarea
										width={1110}
										type={'text'}
										value={editProduct?.product_version?.questions}
										onChange={e =>
											updateProductVersionField('questions', e.target.value)
										}
									/>
								</li>
							</ul>
						</div>
					)}
				</div>
			</BorderArticle>
		</div>
	)
}
