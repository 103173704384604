import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
	clearAvatarState,
	setChatTime,
	createSession,
} from '@/redux/slices/avatarSlices/avatarSlice'

import { LevelPopup } from '@/popups/simulationPopups/LevelPopup'
import { AiFeedbackPopup } from '@/popups/simulationPopups/AiFeedbackPopup'
import { usePopup } from '@/components/layouts/InfoPopup/PopupContext'
import { RootIcon } from '@/components/ui/icons/RootIcon'
import { RootDesc } from '@/components/ui/descriptions/RootDesc'

import styles from './styles.module.scss'

import doctorImage from '@/assets/images/root/doctor.png'

function AvatarImage() {
	const { openPopup, closePopup } = usePopup()
	const { isMobile } = useSelector(state => state.settings)

	const { messages, chatTime, score, scenario_id } = useSelector(
		state => state.avatar
	)

	const dispatch = useDispatch()
	const navigate = useNavigate()
	const location = useLocation()
	const level = location.state?.level

	const handleCloseLevelPopup = () => {
		closePopup()
		dispatch(setChatTime(level.remainingTime))
	}

	const handleTryAgain = async () => {
		dispatch(createSession({ level: level.name, scenario_id }))
		dispatch(clearAvatarState())
		dispatch(setChatTime(level.remainingTime))
		closePopup()
	}

	const handleQuit = async () => {
		closePopup()
		dispatch(clearAvatarState())
		navigate('/simulations')
	}

	const handleClickQuitBtn = async () => {
		if (messages.length > 0 && chatTime > 0) {
			openPopup(<AiFeedbackPopup again={handleTryAgain} quit={handleQuit} />, {
				closeButton: false,
			})
		} else {
			navigate(-1)
		}
	}

	useEffect(() => {
		if (chatTime) {
			let remainingTime = chatTime

			const timer = setInterval(() => {
				if (remainingTime <= 1000 || score === 0) {
					clearInterval(timer)

					openPopup(
						<AiFeedbackPopup again={handleTryAgain} quit={handleQuit} />,
						{ closeButton: false }
					)
				} else {
					remainingTime -= 1000

					dispatch(setChatTime(remainingTime))
				}
			}, 1000)

			return () => clearInterval(timer)
		}
	}, [chatTime, score])

	useEffect(() => {
		openPopup(<LevelPopup close={handleCloseLevelPopup} item={level} />, {
			closeButton: false,
		})

		return () => {
			closePopup()
			dispatch(clearAvatarState())
		}
	}, [])

	return (
		<>
			<img className={styles.doctorImage} src={doctorImage} alt='doctor' />

			<div onClick={handleClickQuitBtn} className={styles.quitBtn}>
				<i>
					<RootIcon width={32} height={32} id={'quit'} />
				</i>

				{!isMobile && (
					<RootDesc>
						<span>End the Visit</span>
					</RootDesc>
				)}
			</div>
		</>
	)
}

export default AvatarImage
