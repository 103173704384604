import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	getFeedback,
	setChatTime,
} from '@/redux/slices/avatarSlices/avatarSlice'

import { RootDesc } from '@/components/ui/descriptions/RootDesc'
import { H3 } from '@/components/ui/titles/H3'
import { RootButton } from '@/components/ui/buttons/RootButton'
import { Spinner } from '@/components/ui/Spinner'

import styles from './styles.module.scss'

export const AiFeedbackPopup = props => {
	const dispatch = useDispatch()

	const { again, quit } = props
	const { feedback, session_id, serverStatus } = useSelector(
		state => state.avatar
	)

	useEffect(() => {
		dispatch(setChatTime(0))
		dispatch(getFeedback(session_id))
	}, [])

	return (
		<div className={styles.feedbackPopup}>
			<div className={styles.feedbackTitle}>
				<H3>AI Feedback</H3>
			</div>

			<div className={styles.feedbackContent}>
				{serverStatus === 'loading' ? (
					<Spinner width={80} height={80} />
				) : (
					<>
						<RootDesc>
							<span>{feedback}</span>
						</RootDesc>

						<div className={styles.feedbackBtns}>
							<RootButton txt={'Try Again'} type={'button'} onClick={again} />

							<RootButton
								txt={'Back to Simulations List'}
								type={'button'}
								onClick={quit}
							/>
						</div>
					</>
				)}
			</div>
		</div>
	)
}
