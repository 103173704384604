import React from 'react'
import { SmallDesc } from '../descriptions/SmallDesc'
import cancelImage from '@/assets/images/popups/cancel-upload.svg'
import styles from './styles.module.scss'

export const DocumentPreview = ({ level, name, onClickCross }) => {
	return (
		<SmallDesc>
			<span className={styles.docPreview}>
				{name}

				<img
					onClick={() => onClickCross(name, level)}
					src={cancelImage}
					alt='remove'
				/>
			</span>
		</SmallDesc>
	)
}
