import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Routes, useNavigate } from 'react-router-dom'

import { Header } from '@/components/layouts/Header'
import { ControlButton } from '@/components/ui/buttons/ControlButton'
import { usePopup } from '@/components/layouts/InfoPopup/PopupContext'
import { HelpPopup } from '@/popups/requestPopups/HelpPopup'
import { H3 } from '@/components/ui/titles/H3'
import { BorderArticle } from '@/components/ui/BorderArticle'
import { setViewTabsId } from '@/redux/slices/simulationsSlice'
import { Call } from '@/screens/simulations/Call'
import { Product } from '@/screens/simulations/Product'
import { Library } from '@/screens/simulations/Library'
import { Footer } from '@/components/layouts/Footer'
import { RootDesc } from '@/components/ui/descriptions/RootDesc'

import styles from './styles.module.scss'

export const Simulations = () => {
	const { viewTabsId } = useSelector(state => state.simulations)
	const { isMobile } = useSelector(state => state.settings)

	const navigate = useNavigate()
	const dispatch = useDispatch()
	const { openPopup } = usePopup()

	const [isOpen, setIsOpen] = useState(false)
	const dropdownRef = useRef(null)

	const toggleDropdown = () => {
		setIsOpen(!isOpen)
	}

	const handleClickOutside = event => {
		if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
			setIsOpen(false)
		}
	}

	const handleOpenPopup = () => {
		openPopup(<HelpPopup />)
	}

	const tabsList = [
		{
			id: 0,
			title: <H3>Call Simulations</H3>,
			link: 'call',
		},
		{
			id: 1,
			title: <H3>Product Simulations</H3>,
			link: 'product',
		},
		{
			id: 2,
			title: <H3>Training Library</H3>,
			link: 'videos',
		},
	]

	const handleClickTab = item => {
		navigate(item.link)
		dispatch(setViewTabsId(item.id))
		setIsOpen(false)
	}

	const switchTab = () => {
		switch (viewTabsId) {
			case 0:
				return { transform: 'translateX(0)', left: '0' }
			case 1:
				return { transform: 'translateX(-50%)', left: '50%' }
			case 2:
				return { transform: 'translateX(-100%)', left: '100%' }
		}
	}

	useEffect(() => {
		navigate('call')

		dispatch(setViewTabsId(0))

		document.addEventListener('mousedown', handleClickOutside)

		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [])

	return (
		<div className={styles.simulations}>
			<Header />

			<div className='container-big'>
				<div className={styles.simulationsHeader}>
					{!isMobile ? (
						<>
							<BorderArticle>
								<div className={styles.tabs}>
									<i style={switchTab()}></i>

									<ul>
										{tabsList &&
											tabsList.map(item => (
												<li
													onClick={() => handleClickTab(item)}
													className={
														item.id === viewTabsId ? styles.activeTab : ''
													}
													key={item.id}
												>
													{item.title}
												</li>
											))}
									</ul>
								</div>
							</BorderArticle>

							<ControlButton
								color='var(--black)'
								txt={'Help'}
								iconId={'question'}
								onClick={handleOpenPopup}
							/>
						</>
					) : (
						<div className={styles.dropdown} ref={dropdownRef}>
							<div className={styles.dropdownHeader} onClick={toggleDropdown}>
								{tabsList[viewTabsId].title}

								<i
									style={
										isOpen
											? { transform: 'rotate(180deg)' }
											: { transform: 'rotate(0deg)' }
									}
								></i>
							</div>

							{isOpen && (
								<div className={styles.dropdownList}>
									<BorderArticle>
										<ul>
											{tabsList &&
												tabsList
													.filter(item => item.id !== viewTabsId)
													.map(item => (
														<li
															key={item.id}
															onClick={() => handleClickTab(item)}
														>
															<RootDesc>
																<span>{item.title}</span>
															</RootDesc>
														</li>
													))}
										</ul>
									</BorderArticle>
								</div>
							)}
						</div>
					)}
				</div>
			</div>

			<Routes>
				<Route path='call/*' element={<Call />} />
				<Route path='product/*' element={<Product />} />
				<Route path='videos/*' element={<Library />} />
			</Routes>

			<Footer />
		</div>
	)
}
