import $api from '../http'

export default class SessionsService {
	static async getSessions({ page, size }) {
		return $api.get(`/api/v1/ai_sessions?page=${page}&page_size=${size}`)
	}

	static async viewSession({ id }) {
		return $api.get(`/api/v1/scenario_sessions/${id}`)
	}
}
