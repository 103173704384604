import React from 'react'
import { Overlay } from '@/components/layouts/Overlay'
import styles from './styles.module.scss'
import closeImg from '@/assets/images/popups/close-white.svg'
import { RootIcon } from '@/components/ui/icons/RootIcon'

export const VideoPopup = ({ video, onClick }) => {
	return (
		<Overlay color={'rgba(65, 64, 64, 0.6)'}>
			<div className={styles.videoPopup}>
				<div onClick={onClick} className={styles.close}>
					<img src={closeImg} alt='close' />
				</div>

				<h2>{video.title}</h2>

				<div
					style={
						video.link
							? { backgroundImage: `url(${video.link})` }
							: { background: 'var(--grey)' }
					}
					className={styles.videoPlayer}
				>
					<div className={styles.videoPlay}>
						<RootIcon id={'play'} />
					</div>

					<div className={styles.videoControls}></div>
				</div>
			</div>
		</Overlay>
	)
}
