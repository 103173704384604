import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import {
	setMessages,
	setUserMessage,
	createSession,
	sendMessage,
} from '@/redux/slices/avatarSlices/avatarSlice'

import { BorderArticle } from '@/components/ui/BorderArticle'
import { RootDesc } from '@/components/ui/descriptions/RootDesc'
import { RootIcon } from '@/components/ui/icons/RootIcon'

import recImage from '@/assets/images/root/rec-message.svg'
import styles from './styles.module.scss'
import { useNavigate } from 'react-router-dom'

var mediaRecorder
var audioText

export const Chat = ({ avatar }) => {
	const dispatch = useDispatch()

	const textareaRef = useRef(null)
	const messagesEndRef = useRef(null)

	const { session_id, messages, userMessage, serverStatus } = useSelector(
		state => state.avatar
	)

	const [textareaHeight, setTextareaHeight] = useState('24rem')
	const [record, setRecord] = useState(false)
	const [text, setText] = useState('')

	let currentText = ''

	const startRec = () => {
		setRecord(true)
		setText('')
	}

	const stopRec = async () => {
		if (record && mediaRecorder && mediaRecorder.state !== 'inactive') {
			mediaRecorder.stop()
			mediaRecorder.stream.getTracks().forEach(track => track.stop())
			mediaRecorder = null
		}

		dispatch(setUserMessage(text))
		setRecord(false)
		console.log('Stop recording!')
	}

	const sendDeepgramAudio = () => {
		navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
			if (!MediaRecorder.isTypeSupported('audio/webm')) {
				return alert('Browser not supported')
			}

			const options = { mimeType: 'video/webm' }
			mediaRecorder = new MediaRecorder(stream, options)

			const socket = new WebSocket(`wss://api.deepgram.com/v1/listen`, [
				'token',
				'90a04632a378d3c90223a9ace2eb9d5d237253f7',
				// import.meta.env.VITE_API_DEEPGRAM_KEY,
			])

			socket.onopen = () => {
				mediaRecorder.addEventListener('dataavailable', async event => {
					if (event.data.size > 0 && socket.readyState == 1) {
						socket.send(event.data)
					}
				})
			}

			mediaRecorder.start(1100)
			console.log('Start recording!')

			socket.onmessage = async message => {
				const received = JSON.parse(message.data)

				if (received.channel) {
					const dataTranscript = received.channel.alternatives[0].transcript

					if (dataTranscript && received.is_final) {
						currentText = currentText.concat(' ' + dataTranscript)
						audioText = currentText

						setText(audioText)
					}
				} else {
					console.warn('Received message does not contain channel:', received)
				}
			}
		})
	}

	const handleChangeMessage = e => {
		setTextareaHeight('auto')
		setTextareaHeight(`${textareaRef.current.scrollHeight}rem`)
		dispatch(setUserMessage(e.target.value))
	}

	const handleSendMessage = async e => {
		e.preventDefault()

		if (userMessage.trim() && userMessage.length !== 0) {
			const user = {
				messageId: uuidv4(),
				sender: 'user',
				text: userMessage,
			}

			dispatch(setMessages(user))

			const resultAction = await dispatch(
				sendMessage({ id: session_id, msg: user.text })
			)

			if (sendMessage.fulfilled.match(resultAction)) {
				dispatch(setUserMessage(''))
			} else {
				console.log('error')
			}
		}
	}

	useEffect(() => {
		setTextareaHeight(
			userMessage ? `${textareaRef.current.scrollHeight}rem` : '24rem'
		)
	}, [userMessage])

	useEffect(() => {
		if (messagesEndRef.current) {
			messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
		}
	}, [messages])

	useEffect(() => {
		if (record) {
			sendDeepgramAudio()
		}
	}, [record])

	useEffect(() => {
		return () => {
			if (record) {
				stopRec()
			}
		}
	}, [])

	return (
		<BorderArticle>
			<div className={styles.gameChat}>
				<ul className={styles.chat}>
					{messages &&
						messages.length > 0 &&
						messages.map((msg, index) => (
							<li
								key={index}
								className={
									msg.sender === 'bot' ? styles.botMessage : styles.userMessage
								}
							>
								<RootDesc>
									{msg.sender === 'bot' ? (
										<b>{msg.text}</b>
									) : (
										<span>{msg.text}</span>
									)}
								</RootDesc>
							</li>
						))}

					<div ref={messagesEndRef} />
				</ul>

				<div className={styles.chatControls}>
					<div
						style={!avatar ? { width: '100%' } : {}}
						className={styles.textarea}
					>
						<textarea
							disabled={serverStatus === 'loading' ? true : false}
							ref={textareaRef}
							type={'text'}
							value={userMessage}
							onChange={handleChangeMessage}
							style={{
								height: textareaHeight,
								maxHeight: '100rem',
								overflowY: 'scroll',
							}}
						/>
					</div>

					<div
						onClick={handleSendMessage}
						style={serverStatus !== 'loading' ? { animation: 'none' } : {}}
						className={styles.spinner}
					>
						<RootIcon
							width={26}
							height={26}
							id={
								serverStatus === 'loading' ? 'spinner-upload' : 'send-message'
							}
						/>
					</div>

					{avatar && (
						<div
							className={
								!record ? styles.rec : styles.rec + ' ' + styles.recActive
							}
							onClick={!record ? startRec : stopRec}
						>
							<button
								disabled={serverStatus === 'loading' ? true : false}
								type='button'
							>
								<img src={recImage} alt='rec' />
							</button>
						</div>
					)}
				</div>
			</div>
		</BorderArticle>
	)
}
