import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
	clearViewUser,
	viewUser,
} from '@/redux/slices/userSlices/viewUserSlice'

import { usePopup } from '../InfoPopup/PopupContext'
import { RootDesc } from '@/components/ui/descriptions/RootDesc'
import { ControlButton } from '@/components/ui/buttons/ControlButton'
import { ChangeRequestPopup } from '@/popups/requestPopups/ChangeRequestPopup'
import { UploadFileProgressPopup } from '@/popups/uploadPopups/UploadFileProgressPopup'
import { ChangePassPopup } from '@/popups/requestPopups/ChangePassPopup'
import { Spinner } from '@/components/ui/Spinner'
import UserImage from '@/components/ui/UserImage'

import photoImage from '@/assets/images/root/photo.svg'
import styles from './styles.module.scss'

const UserInfoField = ({ label, value }) => (
	<li>
		<RootDesc>
			<b className='block min-w-[150rem]'>{label}</b>
		</RootDesc>

		<RootDesc>
			<span>{value}</span>
		</RootDesc>
	</li>
)

export const ProfileLayout = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const id = useParams().id
	const { openPopup } = usePopup()
	const { user, serverStatus } = useSelector(state => state.viewUser)

	const handleOpenPopup = () => {
		openPopup(<ChangeRequestPopup />)
	}

	const handleClickImage = () => {
		openPopup(<UploadFileProgressPopup type={'image'} />)
	}

	const handleClickChangePass = () => {
		openPopup(<ChangePassPopup />)
	}

	useEffect(() => {
		dispatch(viewUser({ id }))

		return () => {
			dispatch(clearViewUser())
		}
	}, [dispatch, id])

	return (
		<div className={styles.profileWrapper}>
			{serverStatus === 'loading' ? (
				<Spinner width={80} height={80} />
			) : (
				<div className={styles.profile}>
					<div className={styles.profileInfo}>
						<UserImage
							onClick={user?.role !== 'Admin' ? handleClickImage : undefined}
							photoImage={user?.role !== 'Admin' ? photoImage : undefined}
							photoFile={user?.metadata}
							user={user}
						/>
					</div>

					<div className={styles.content}>
						<ul>
							<UserInfoField label='Last Name' value={user?.lastname} />

							<UserInfoField label='Email' value={user?.email} />

							<li>
								<RootDesc>
									<b className='block min-w-[150rem]'>Password</b>
								</RootDesc>

								<RootDesc>
									<span>
										*********
										{user?.role !== 'Admin' && (
											<i className={styles.profileEditPassword}>
												<ControlButton
													color='var(--blue)'
													txt='Edit'
													onClick={handleClickChangePass}
												/>
											</i>
										)}
									</span>
								</RootDesc>
							</li>

							<UserInfoField label='Default Role' value={user?.role} />

							{user?.role !== 'Admin' && (
								<>
									<UserInfoField
										label='Product Line'
										value={user?.products_line}
									/>

									<UserInfoField label='Country' value={user?.country} />
								</>
							)}
						</ul>

						<ControlButton
							color='var(--blue)'
							txt={user?.role !== 'Admin' ? 'Change Request' : 'Edit'}
							onClick={
								user?.role !== 'Admin'
									? handleOpenPopup
									: () => navigate(`/admin-panel/users/edit/${id}`)
							}
						/>
					</div>
				</div>
			)}
		</div>
	)
}
