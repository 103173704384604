import React from 'react'
import styles from './styles.module.scss'

export const Overlay = ({ color, children }) => {
	return (
		<div
			style={
				color
					? { background: `${color}` }
					: { background: `rgba(217, 217, 217, 0.5)` }
			}
			className={styles.overlay}
		>
			{children}
		</div>
	)
}
