import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	isMobile: window.innerWidth < 768 ? true : false,
}

const settingsSlice = createSlice({
	name: 'settings',
	initialState,
	reducers: {
		setIsMobile(state, action) {
			state.isMobile = action.payload
		},
	},
})

export const { setIsMobile } = settingsSlice.actions
export default settingsSlice.reducer
