import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	clearProductOptions,
	getProductLine,
} from '@/redux/slices/productSlices/productOptionsSlice'
import {
	clearProducts,
	getAllProducts,
} from '@/redux/slices/productSlices/productsSlice'

import { SimulationsLayout } from '@/components/layouts/SimulationsLayout'
import { Spinner } from '@/components/ui/Spinner'

import styles from './styles.module.scss'

export const Product = () => {
	const dispatch = useDispatch()

	const statusProducts = useSelector(state => state.products.serverStatus)
	const statusLine = useSelector(state => state.productOptions.serverStatus)

	const { productLine } = useSelector(state => state.productOptions)
	const { products } = useSelector(state => state.products)
	const { role, product_line } = useSelector(state => state.candidate)

	let allProducts = []

	if (role === 'Admin') {
		allProducts = productLine.map(line => ({
			id: line.id,
			name: line.name,
			products: products.filter(product => product.product_line === line.name),
		}))

		allProducts.sort((a, b) => a.name.localeCompare(b.name))
	}

	useEffect(() => {
		dispatch(getProductLine())
		dispatch(getAllProducts())

		return () => {
			dispatch(clearProductOptions())
			dispatch(clearProducts())
		}
	}, [])

	return (
		<div className='container-big'>
			<div className={styles.product}>
				{statusProducts === 'loading' || statusLine === 'loading' ? (
					<Spinner width={80} height={80} />
				) : (
					<ul>
						{role === 'Admin' ? (
							allProducts &&
							allProducts.map(item => (
								<li key={item.id}>
									<SimulationsLayout title={item.name} cards={item.products} />
								</li>
							))
						) : (
							<li key={product_line}>
								<SimulationsLayout title={product_line} cards={products} />
							</li>
						)}
					</ul>
				)}
			</div>
		</div>
	)
}
