import React from 'react'

import { H3 } from '@/components/ui/titles/H3'
import { StatisticCardsLayout } from '../StatisticCardsLayout'

import activeImage from '@/assets/images/pages/dashboard/user.png'
import successImage from '@/assets/images/pages/dashboard/user-set.png'
import rejectImage from '@/assets/images/pages/dashboard/user-rem.png'
import styles from './styles.module.scss'

export const Statistic = () => {
	const statisticList = [
		{
			id: 0,
			icon: activeImage,
			title: 'Total Attempts',
			total: '120',
			totalChange: '+15',
		},
		{
			id: 1,
			icon: successImage,
			title: 'Successfull Calls',
			total: '88',
			totalChange: '+25',
		},
		{
			id: 2,
			icon: rejectImage,
			title: 'Calls Rejections',
			total: '32',
			totalChange: '-3',
		},
	]

	return (
		<div className={styles.statistic}>
			<div className={styles.statisticHead}>
				<H3>Statistics</H3>
			</div>

			<StatisticCardsLayout list={statisticList} />
		</div>
	)
}
