import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import SessionsService from '@/services/SessionsService'

export const getSessions = createAsyncThunk(
	'sessions',
	async ({ page, size }) => {
		try {
			const response = await SessionsService.getSessions({ page, size })

			return response.data
		} catch (err) {
			throw new Error('Unauthorized!')
		}
	}
)

const initialState = {
	sessions: [],
	page: 1,
	size: 5,
	sort: 'Scenario',
	totalPages: 1,
	error: '',
	serverStatus: '',
}

const sessionsSlice = createSlice({
	name: 'sessions',
	initialState,
	reducers: {
		setPage(state, action) {
			state.page = action.payload
		},
		setSize(state, action) {
			state.size = action.payload
		},
		setSort(state, action) {
			state.sort = action.payload
		},
		setTotalPages(state, action) {
			state.totalPages = action.payload
		},
		setError(state, action) {
			state.error = action.payload
		},
		setServerStatus(state, action) {
			state.serverStatus = action.payload
		},
		clearSessions() {
			return initialState
		},
	},
	extraReducers: builder => {
		builder
			.addCase(getSessions.pending, state => {
				state.serverStatus = 'loading'
				state.error = ''
			})
			.addCase(getSessions.fulfilled, (state, action) => {
				state.sessions = action.payload.data
				state.totalPages = action.payload.total
					? Math.ceil(action.payload.total / state.size)
					: state.totalPages
				state.error = ''
				state.serverStatus = 'success'
			})
			.addCase(getSessions.rejected, (state, action) => {
				state.error = 'Don`t get sessions!'
				state.serverStatus = 'error'
			})
	},
})

export const {
	setPage,
	setSort,
	clearSessions,
	setTotalPages,
	setError,
	setSize,
	setServerStatus,
} = sessionsSlice.actions
export default sessionsSlice.reducer
