import React, { useState } from 'react'

import { H3 } from '@/components/ui/titles/H3'
import { RootDesc } from '@/components/ui/descriptions/RootDesc'
import { RootInput } from '@/components/ui/inputs/RootInput'
import { Spinner } from '@/components/ui/Spinner'
import { RootButton } from '@/components/ui/buttons/RootButton'
import { RootIcon } from '@/components/ui/icons/RootIcon'
import { SmallDesc } from '@/components/ui/descriptions/SmallDesc'

import checkImage from '@/assets/images/root/check.png'
import crossImage from '@/assets/images/root/cross.png'
import styles from './styles.module.scss'
import { FormError } from '../../../components/ui/FormError'

const PasswordInput = ({ label, value, onChange, show, toggleShow }) => (
	<div className={styles.input}>
		<RootInput
			width={307}
			label={label}
			type={show ? 'text' : 'password'}
			value={value}
			required={true}
			onChange={onChange}
		/>
		<div onClick={toggleShow}>
			<RootIcon width={24} height={24} id={'show-pass'} />
		</div>
	</div>
)

export const ChangePassPopup = () => {
	const [match, setMatch] = useState(true)
	const [passwords, setPasswords] = useState({
		newPassword: { input: '', show: false },
		repeatPassword: { input: '', show: false },
	})

	const handleSubmit = e => {
		e.preventDefault()

		setMatch(passwords.newPassword.input === passwords.repeatPassword.input)

		if (match) {
			console.log('send')
		}
	}

	const toggleShowPassword = field => {
		setPasswords(prev => ({
			...prev,
			[field]: { ...prev[field], show: !prev[field].show },
		}))
	}

	return (
		<div className={styles.changePass}>
			<H3>Change Password</H3>

			<form onSubmit={handleSubmit} className={styles.changePassForm}>
				<PasswordInput
					label={'Type New Password'}
					value={passwords.newPassword.input}
					onChange={e =>
						setPasswords(prev => ({
							...prev,
							newPassword: { ...prev.newPassword, input: e.target.value },
						}))
					}
					show={passwords.newPassword.show}
					toggleShow={() => toggleShowPassword('newPassword')}
				/>

				<PasswordInput
					label={'Repeat New Password'}
					value={passwords.repeatPassword.input}
					onChange={e =>
						setPasswords(prev => ({
							...prev,
							repeatPassword: { ...prev.repeatPassword, input: e.target.value },
						}))
					}
					show={passwords.repeatPassword.show}
					toggleShow={() => toggleShowPassword('repeatPassword')}
				/>

				{!match && <FormError error={'Passwords doesn’t match'} bottom={399} />}

				<div className={styles.description}>
					<RootDesc>
						<b>Password Requirements:</b>
					</RootDesc>

					<ul>
						<li>
							<img src={checkImage} alt='icon' />

							<RootDesc>
								<b>Minimum 8 Characters:</b> <br />
								<span>Your password must be at least 8 characters long.</span>
							</RootDesc>
						</li>

						<li>
							<img src={crossImage} alt='icon' />

							<RootDesc>
								<b>Include Uppercase and Lowercase Letters:</b> <br />
								<span>
									Use both uppercase (A-Z) and lowercase (a-z) letters.
								</span>
							</RootDesc>
						</li>

						<li>
							<img src={checkImage} alt='icon' />

							<RootDesc>
								<b>Include a Number or Special Character:</b> <br />
								<span>
									Add at least one number (0-9) or special character (e.g., !,
									@, #, $).
								</span>
							</RootDesc>
						</li>
					</ul>
				</div>

				<RootButton
					txt={'Save New Password'}
					type={'submit'}
					// disabled={!password || !repeatPassword || serverStatus === 'loading'}
				/>

				{/* {serverStatus === 'loading' && <Spinner width={50} height={50} />} */}
			</form>
		</div>
	)
}
