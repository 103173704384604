import React from 'react'

import { RootDesc } from '@/components/ui/descriptions/RootDesc'
import { Link } from 'react-router-dom'

import logoImage from '@/assets/images/root/logo-white.svg'
import styles from './styles.module.scss'

export const Footer = () => {
	const footerLinkList = [
		{
			id: 0,
			name: 'Privacy Policy',
			link: '/privacy',
		},
		{
			id: 1,
			name: 'Terms of Service',
			link: '/terms',
		},
		{
			id: 2,
			name: 'Disclaimer',
			link: '/disclaimer',
		},
	]

	return (
		<div className={styles.footerWrapper}>
			<div className='container-big phone:p-0'>
				<div className={styles.footer}>
					<div className={styles.footerInfo}>
						<img src={logoImage} alt='logo' />

						<RootDesc>
							<span className='text-[var(--white)]'>
								© 2024 Vivanti SA. All rights reserved.
							</span>
						</RootDesc>
					</div>

					<div className={styles.footerContent}>
						<h2>
							Ava.vivanti.eu is a service of Vivanti SA, specializing in
							AI-driven simulations <br /> to enhance commercial excellence in
							the pharmaceutical industry.
						</h2>

						<div className={styles.contentBottom}>
							<ul>
								{footerLinkList.map(item => (
									<li key={item.id}>
										<RootDesc>
											<Link to={item.link}>{item.name}</Link>
										</RootDesc>
									</li>
								))}
							</ul>

							<RootDesc>
								<span className='text-[var(--white)]'>
									For inquiries, contact us at:{' '}
									<a target='_blank' href='mailto:info@vivanti.eu'>
										info@vivanti.eu
									</a>
								</span>
							</RootDesc>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
