import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import ScenariosService from '@/services/ScenariosService'

export const getEditScenario = createAsyncThunk(
	'scenario/edit',
	async ({ id }) => {
		try {
			const response = await ScenariosService.viewScenario({ id })

			return response.data
		} catch (err) {
			throw new Error('Unauthorized!')
		}
	}
)

export const updateScenario = createAsyncThunk(
	'scenario/update',
	async ({ id, scenario }) => {
		try {
			const response = await ScenariosService.updateScenario({ id, scenario })

			return response.data
		} catch (err) {
			throw new Error('Unauthorized!')
		}
	}
)

const initialState = {
	isScenarioEdit: false,
	saveScenarioEdit: false,
	error: '',
	serverStatus: '',
	editScenario: null,
	initialEditScenario: null,
}

const editScenarioSlice = createSlice({
	name: 'edit-scenario',
	initialState,
	reducers: {
		setIsScenarioEdit(state, action) {
			state.isScenarioEdit = action.payload
		},
		setSaveScenarioEdit(state, action) {
			state.saveScenarioEdit = action.payload
		},
		setError(state, action) {
			state.setError = action.payload
		},
		setServerStatus(state, action) {
			state.serverStatus = action.payload
		},
		setEditScenario(state, action) {
			state.editScenario = action.payload
		},
		clearEditScenario() {
			return initialState
		},
	},
	extraReducers: builder => {
		builder
			.addCase(getEditScenario.pending, state => {
				state.serverStatus = 'loading'
			})
			.addCase(getEditScenario.fulfilled, (state, action) => {
				state.editScenario = action.payload
				state.initialEditScenario = action.payload
				state.error = ''
				state.serverStatus = 'success'
			})
			.addCase(getEditScenario.rejected, state => {
				state.error = 'Don`t get edit scenario!'
				state.serverStatus = 'error'
			})

			.addCase(updateScenario.pending, state => {
				state.serverStatus = 'loading'
			})
			.addCase(updateScenario.fulfilled, (state, action) => {
				state.editScenario = action.payload
				state.initialEditScenario = action.payload
				state.error = ''
				state.serverStatus = 'success'
			})
			.addCase(updateScenario.rejected, state => {
				state.error = 'Don`t update scenario!'
				state.serverStatus = 'error'
			})
	},
})

export const {
	setEditScenario,
	setIsScenarioEdit,
	setSaveScenarioEdit,
	setError,
	setServerStatus,
	clearEditScenario,
} = editScenarioSlice.actions
export default editScenarioSlice.reducer
