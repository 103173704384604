import React from 'react'
import styles from './styles.module.scss'
import { SmallDesc } from '../descriptions/SmallDesc'

export const FormError = ({ error, bottom }) => {
	return (
		<div
			className={styles.error}
			style={{
				bottom: `${bottom}rem`,
			}}
		>
			<SmallDesc>
				<span>{error}</span>
			</SmallDesc>
		</div>
	)
}
