import $api from '../http'

export default class AvatarService {
	static async createSession({ level, scenario_id }) {
		return $api.post('/api/v1/scenario_sessions', { level, scenario_id })
	}

	static async sendMessage({ id, msg }) {
		return $api.post(`/api/v1/scenario_sessions/${id}/message`, { id, msg })
	}

	static async getFeedback(id) {
		return $api.post(`/api/v1/scenario_sessions/${id}/feedback`)
	}

	static async getToken(id) {
		return $api.post(`/api/v1/scenario_sessions/${id}/heygen_token`)
	}
}
