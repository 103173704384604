import React, { useRef } from 'react'
import styles from './Activity.module.scss'
import { H3 } from '@/components/ui/titles/H3'
import { RootDesc } from '@/components/ui/descriptions/RootDesc'
import { SmallDesc } from '@/components/ui/descriptions/SmallDesc'

export const Activity = () => {
	const usersList = [
		{
			id: 0,
			avatarImage: '',
			country: <>&#127465;&#127466;</>,
			name: 'Jorgen Shulz',
			timeAgo: '15 minuts ago',
			scenario: 'Scenario1',
			level: 'easy',
		},
		{
			id: 1,
			avatarImage: '',
			country: <>&#127464;&#127477;</>,
			name: 'Jaque Monet',
			timeAgo: '24 minuts ago',
			scenario: 'Scenario3',
			level: 'medium',
		},
		{
			id: 2,
			avatarImage: '',
			country: <>&#127466;&#127462;</>,
			name: 'Bianca Hernandez',
			timeAgo: '2 hours ago',
			scenario: 'Seasonal allergie',
			level: 'easy',
		},
		{
			id: 3,
			avatarImage: '',
			country: <>&#127465;&#127466;</>,
			name: 'Emma Schmidt',
			timeAgo: '3 hours 6 minuts ago',
			scenario: 'Scenario2',
			level: 'hard',
		},
		{
			id: 4,
			avatarImage: '',
			country: <>&#127466;&#127462;</>,
			name: 'Jaque Monet',
			timeAgo: '4 hours 24 minuts ago',
			scenario: 'Seasonal Allergies in Eastern Europe',
			level: 'medium',
		},
		{
			id: 5,
			avatarImage: '',
			country: <>&#127466;&#127462;</>,
			name: 'Emma Schmidt',
			timeAgo: '1 hours ago',
			scenario: 'Scenario2',
			level: 'hard',
		},
		{
			id: 6,
			avatarImage: '',
			country: <>&#127464;&#127477;</>,
			name: 'Emma Schmidt',
			timeAgo: '3 hours 6 minuts ago',
			scenario: 'Scenario2',
			level: 'hard',
		},
		{
			id: 7,
			avatarImage: '',
			country: <>&#127465;&#127466;</>,
			name: 'Jaque Monet',
			timeAgo: '24 minuts ago',
			scenario: 'Scenario3',
			level: 'medium',
		},
	]

	const listRef = useRef(null)

	const switchColor = item => {
		switch (true) {
			case item.level === 'easy':
				return { background: 'var(--blueLight)', color: 'var(--blue' }
			case item.level === 'medium':
				return { background: 'var(--yellow)', color: 'var(--yellowDark' }
			case item.level === 'hard':
				return { background: 'var(--red)', color: 'var(--redDark' }
		}
	}

	return (
		<div className={styles.activity}>
			<div className={styles.activityHead}>
				<H3>Last Activity</H3>
			</div>

			<ul ref={listRef} className={styles.userList}>
				{usersList &&
					usersList.map(item => (
						<li key={item.id}>
							<div className={styles.itemHead}>
								<img src={item.avatarImage} alt='avatar' />

								<RootDesc>
									<span>
										{item.country}&nbsp;&nbsp;{item.name}
									</span>
								</RootDesc>
							</div>

							<div className={styles.itemFooter}>
								<div className={styles.itemFooterContent}>
									<SmallDesc>
										<span className='text-[var(--grey)]'>{item.timeAgo}</span>
									</SmallDesc>
									<RootDesc>
										<span>{item.scenario}</span>
									</RootDesc>
								</div>
								<div style={switchColor(item)} className={styles.itemLevel}>
									{item.level}
								</div>
							</div>
						</li>
					))}
			</ul>

			<div className={styles.scrollDownBtn}>
				<i></i>
			</div>
		</div>
	)
}
