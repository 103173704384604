import React, { useEffect, useRef, useState } from 'react'

import { BorderArticle } from '@/components/ui/BorderArticle'

import minVol from '@/assets/images/root/vol-min.svg'
import maxVol from '@/assets/images/root/vol-max.svg'
import muteVol from '@/assets/images/root/vol-mute.svg'

import styles from './styles.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { setVolume } from '@/redux/slices/avatarSlices/avatarSlice'

export const Controls = () => {
	const { isMobile } = useSelector(state => state.settings)
	const { volume } = useSelector(state => state.avatar)

	const dispatch = useDispatch()
	const volumeRef = useRef(null)

	const handleVolumeChange = event => {
		dispatch(setVolume(+event.target.value))
	}

	useEffect(() => {
		if (volumeRef.current && !isMobile) {
			const maxWidth = volumeRef.current.offsetWidth - 8
			const position = (volume / 100) * maxWidth

			volumeRef.current.style.setProperty(
				'--i-position',
				`${position * 100}rem`
			)
		} else if (volumeRef.current && isMobile) {
			const maxHeight = volumeRef.current.offsetHeight - 8
			const position = (volume / 100) * maxHeight

			volumeRef.current.style.setProperty(
				'--i-position',
				`${position * 100}rem`
			)
		}
	}, [volume])

	return (
		<div className={styles.gameControlsWrap}>
			<BorderArticle>
				<div className={styles.gameControls}>
					<div>
						<div
							className={styles.controlImg}
							onClick={() => dispatch(setVolume(0))}
						>
							<img src={minVol} alt='min' />
						</div>

						<div ref={volumeRef} className={styles.gameVolume}>
							<label htmlFor='volume'>
								<input
									min={0}
									max={1}
									step={0.05}
									id='volume'
									type='range'
									value={volume}
									onChange={handleVolumeChange}
								/>

								<div
									className={styles.bar}
									style={
										isMobile
											? { height: 'var(--i-position)' }
											: { width: 'var(--i-position)' }
									}
								>
									<i
										className={styles.thumb}
										style={
											isMobile
												? { bottom: 'var(--i-position)' }
												: { left: 'var(--i-position)' }
										}
									></i>
								</div>
							</label>
						</div>

						<div
							className={styles.controlImg}
							onClick={() => dispatch(setVolume(1))}
						>
							<img src={maxVol} alt='max' />
						</div>
					</div>

					<div
						className={styles.controlImg}
						onClick={() => dispatch(setVolume(0))}
					>
						<img
							style={{ width: '27rem', height: '27rem' }}
							src={muteVol}
							alt='mute'
						/>
					</div>
				</div>
			</BorderArticle>
		</div>
	)
}
