import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
	setScenarioId,
	setScenarioLevel,
	createSession,
} from '@/redux/slices/avatarSlices/avatarSlice'

import { RootDesc } from '@/components/ui/descriptions/RootDesc'
import { Spinner } from '../Spinner'

import crownImage from '@/assets/images/root/crown.svg'
import errorImage from '@/assets/images/popups/error-upload.svg'

import styles from './styles.module.scss'

export const PreviewCard = props => {
	const { name, image, until, mask, levelList, id } = props

	const { serverStatus } = useSelector(state => state.avatar)

	const navigate = useNavigate()
	const dispatch = useDispatch()

	const levels = [
		{
			id: 0,
			name: 'easy',
			remainingTime: 600000, // 10 minuts = 600000
		},
		{
			id: 1,
			name: 'medium',
			remainingTime: 480000, // 8 minuts = 480000
		},
		{
			id: 2,
			name: 'hard',
			remainingTime: 300000, // 5 minuts = 300000
		},
	]

	const handleClickLevel = async (item, id) => {
		dispatch(setScenarioId(id))
		dispatch(setScenarioLevel(item.name))

		const resultAction = await dispatch(
			createSession({ level: item.name, scenario_id: id })
		)

		if (!createSession.fulfilled.match(resultAction)) {
			navigate('/simulations/call')
		} else {
			navigate('/chat-game', { state: { level: item } })
		}
	}

	return (
		<div className={styles.card}>
			{serverStatus === 'loading' ? (
				<Spinner width={40} height={40} />
			) : (
				<>
					{image && (
						<img
							className={styles.cardPreview}
							src={image}
							alt='preview-image'
						/>
					)}

					{mask && (
						<div
							className={styles.maskPreview}
							style={{ background: `var(--${mask})` }}
						></div>
					)}

					{until && (
						<div className={styles.cardHead}>
							<img src={errorImage} alt='warning' />

							<RootDesc>
								<span>
									Until <b>{until}</b>
								</span>
							</RootDesc>
						</div>
					)}

					{name && <h2>{name}</h2>}

					{levelList && (
						<ul className={styles.cardFooter}>
							{levels.map(item => (
								<li
									onClick={() => handleClickLevel(item, id)}
									key={item?.id}
									// style={
									// 	item?.name === 'open' || item?.name === 'success'
									// 		? { pointerEvents: 'all' }
									// 		: { pointerEvents: 'none' }
									// }
								>
									{/* {item?.name === 'success' && <img src={crownImage} alt='icon' />} */}

									<RootDesc>
										<span>
											<b
												style={
													item?.name === 'success'
														? { color: 'var(--blueLight)' }
														: {}
												}
											>
												{item?.name}
											</b>
										</span>
									</RootDesc>
								</li>
							))}
						</ul>
					)}
				</>
			)}
		</div>
	)
}
