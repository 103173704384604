import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
	setEditScenario,
	setIsScenarioEdit,
	clearEditScenario,
	getEditScenario,
	updateScenario,
} from '@/redux/slices/scenarioSlices/editScenarioSlice'
import {
	getProducts,
	clearScenarioOptions,
} from '@/redux/slices/scenarioSlices/scenarioOptionsSlice'
import { v4 as uuidv4 } from 'uuid'

import { isEqual } from '@/helpers'
import { usePopup } from '../InfoPopup/PopupContext'
import { BorderArticle } from '@/components/ui/BorderArticle'
import { RootDesc } from '@/components/ui/descriptions/RootDesc'
import { ControlButton } from '@/components/ui/buttons/ControlButton'
import { EditInput } from '@/components/ui/inputs/EditInput'
import { UploadImagePopup } from '@/popups/uploadPopups/UploadImagePopup'
import { DropCalendar } from '@/components/ui/DropCalendar'
import { BackButton } from '@/components/ui/buttons/BackButton'
import { MaskList } from '@/components/ui/MaskList'
import { DocumentPreview } from '@/components/ui/DocumentPreview'
import EditSelect from '@/components/ui/inputs/EditSelect'
import { RootTextarea } from '@/components/ui/inputs/RootTextarea'
import { Spinner } from '@/components/ui/Spinner'
import { UploadFilePopup } from '@/popups/uploadPopups/UploadFilePopup'
import { PreviewCard } from '@/components/ui/PreviewCard'
import { FillDataPopup } from '@/popups/attentionPopups/FillDataPopup'
import { SuccessfulSavePopup } from '@/popups/attentionPopups/SuccessfulSavePopup'

import styles from './styles.module.scss'

const ScenarioEditField = ({ label, value, onChange }) => (
	<li>
		<RootDesc>
			<b className='block w-[280rem] phone:w-[100%]'>{label}</b>
		</RootDesc>

		<RootTextarea
			width={1110}
			type={'text'}
			value={value}
			onChange={onChange}
		/>
	</li>
)

export const ScenarioEditLayout = () => {
	const { openPopup } = usePopup()

	const id = useParams().id
	const dispatch = useDispatch()

	const { isMobile } = useSelector(state => state.settings)
	const { editScenario, initialEditScenario, serverStatus } = useSelector(
		state => state.editScenario
	)
	const { products } = useSelector(state => state.scenarioOptions)

	const [fileList, setFileList] = useState([])
	const [photoFile, setPhotoFile] = useState(null)

	const updateScenarioField = (fieldName, value) => {
		dispatch(setEditScenario({ ...editScenario, [fieldName]: value }))
	}

	const handleClickRemovePhoto = () => {
		setPhotoFile(null)
		updateScenarioField('cover', null)
	}

	const handleClickCover = useCallback(
		color => updateScenarioField('mask', color),
		[updateScenarioField]
	)

	const handleClickUploadImage = () => {
		openPopup(<UploadImagePopup setPhotoFile={setPhotoFile} />)
	}

	const handleClickUploadFile = () => {
		openPopup(<UploadFilePopup fileList={fileList} setFileList={setFileList} />)
	}

	const handleClickRemoveFile = fileName => {
		setFileList(prevFileList =>
			prevFileList.filter(file => file.file.name !== fileName)
		)
	}

	const handleSelect = selectedOption => {
		const productId = selectedOption.id
		const currentProductIds = editScenario.product_ids || []

		if (currentProductIds.includes(productId)) {
			const updatedProductIds = currentProductIds.filter(id => id !== productId)

			updateScenarioField('product_ids', updatedProductIds)
		} else {
			const updatedProductIds = [...currentProductIds, productId]

			updateScenarioField('product_ids', updatedProductIds)
		}
	}

	const handleClickSave = async () => {
		if (
			editScenario.name === '' ||
			editScenario.mask === '' ||
			editScenario.purpose === '' ||
			editScenario.objectives === '' ||
			editScenario.key_messages === ''
		) {
			openPopup(<FillDataPopup />)
		} else {
			const scenario = {
				scenario: {
					name: editScenario.name,
					mask: editScenario.mask,
					purpose: editScenario.purpose,
					objectives: editScenario.objectives,
					focus: editScenario.focus,
					structure: editScenario.structure,
					key_messages: editScenario.key_messages,
					script: editScenario.script,
					questions: editScenario.questions,
				},
			}

			const resultAction = await dispatch(updateScenario({ id, scenario }))

			if (updateScenario.fulfilled.match(resultAction)) {
				openPopup(<SuccessfulSavePopup title={'scenario'} />)
			} else {
				dispatch(setError('Save error!'))
			}
		}
	}

	useEffect(() => {
		if (editScenario && initialEditScenario) {
			dispatch(setIsScenarioEdit(!isEqual(editScenario, initialEditScenario)))
		}
	}, [editScenario, initialEditScenario, dispatch])

	useEffect(() => {
		dispatch(getEditScenario({ id }))
		dispatch(getProducts())

		return () => {
			dispatch(clearEditScenario())
			dispatch(clearScenarioOptions())
		}
	}, [dispatch])

	return (
		<div className={styles.scenarioWrapper}>
			<BorderArticle>
				<div className={styles.scenario}>
					<BackButton />

					{serverStatus === 'loading' ? (
						<Spinner width={80} height={80} />
					) : (
						<div className={styles.scenarioContent}>
							<div className={styles.scenarioEditContent}>
								<div className={styles.scenarioContentHeadWrapper}>
									<div className={styles.scenarioContentHead}>
										<div className='flex items-center gap-[16rem]'>
											<EditInput
												width={!isMobile ? 350 : 210}
												placeholder={`Scenario name*`}
												label='scenario-name'
												type='text'
												value={editScenario?.name}
												onChange={e =>
													updateScenarioField('name', e.target.value)
												}
											/>

											<ControlButton
												color='var(--green)'
												txt={'Save'}
												onClick={() => handleClickSave()}
											/>
										</div>

										<div className='flex items-start gap-[16rem] phone:flex-col'>
											<RootDesc>
												<b className='block w-[150rem]'>
													Choose Background<sup>*</sup>
												</b>
											</RootDesc>

											<MaskList
												onClickCover={color => handleClickCover(color)}
											/>
										</div>

										<div className='flex items-start gap-[16rem] phone:flex-col'>
											<RootDesc>
												<b className='block w-[150rem]'>Cover</b>
											</RootDesc>

											<div className='flex flex-col items-start gap-[16rem]'>
												{photoFile ||
													(editScenario?.cover && (
														<DocumentPreview
															name={
																photoFile?.name ||
																editScenario?.cover_metadata?.filename
															}
															onClickCross={() => handleClickRemovePhoto()}
														/>
													))}

												<ControlButton
													color='var(--violetLight)'
													txt={'Upload Image'}
													onClick={() => handleClickUploadImage()}
												/>
											</div>
										</div>
									</div>

									<div className='flex flex-col gap-[24rem] items-start'>
										<RootDesc>
											<b>Cover Preview</b>
										</RootDesc>

										<BorderArticle>
											<PreviewCard
												name={editScenario?.name}
												mask={editScenario?.mask}
												image={
													photoFile
														? URL.createObjectURL(photoFile)
														: editScenario?.cover || undefined
												}
											/>
										</BorderArticle>
									</div>
								</div>
							</div>

							<ul
								className={styles.scenarioContentBody + ' ' + styles.editBody}
							>
								<li>
									<RootDesc>
										<b className='block w-[280rem]'>
											Related Products<sup>*</sup>
										</b>
									</RootDesc>

									{products && products.length > 0 && (
										<div className='w-[1110rem] phone:w-[100%]'>
											<EditSelect
												width={500}
												searchPlaceholder='Start typing Product Name'
												multiSelect={true}
												activeOption={
													editScenario?.products
														? editScenario?.products
																.split(',')
																.map(item => item.trim())
														: []
												}
												options={products}
												onSelect={selectedOption =>
													handleSelect(selectedOption)
												}
											/>
										</div>
									)}
								</li>

								<ScenarioEditField
									value={editScenario?.purpose}
									onChange={e => updateScenarioField('purpose', e.target.value)}
									label={
										<>
											Purpose of the Call<sup>*</sup>
										</>
									}
								/>

								<ScenarioEditField
									value={editScenario?.objectives}
									onChange={e =>
										updateScenarioField('objectives', e.target.value)
									}
									label={
										<>
											Objectives of the Call<sup>*</sup>
										</>
									}
								/>

								<ScenarioEditField
									value={editScenario?.focus}
									onChange={e => updateScenarioField('focus', e.target.value)}
									label='Focus of the Call'
								/>

								<ScenarioEditField
									value={editScenario?.key_messages}
									onChange={e =>
										updateScenarioField('key_messages', e.target.value)
									}
									label={
										<>
											Key Messages<sup>*</sup>
										</>
									}
								/>

								<ScenarioEditField
									value={editScenario?.structure}
									onChange={e =>
										updateScenarioField('structure', e.target.value)
									}
									label='Call Structure'
								/>

								<ScenarioEditField
									value={editScenario?.script}
									onChange={e => updateScenarioField('script', e.target.value)}
									label='Call Script'
								/>

								<li>
									<RootDesc>
										<b className='block w-[280rem]'>Related Documents</b>
									</RootDesc>

									<div className={styles.scenarioDocuments}>
										{fileList && fileList.length > 0 && (
											<div className={styles.scenarioDocumentsPreview}>
												{fileList.map(file => (
													<div key={uuidv4()}>
														<DocumentPreview
															name={file.file.name}
															onClickCross={name => handleClickRemoveFile(name)}
														/>
													</div>
												))}
											</div>
										)}

										<ControlButton
											color='var(--violetLight)'
											txt={'Upload .txt'}
											onClick={() => handleClickUploadFile()}
										/>
									</div>
								</li>

								<ScenarioEditField
									value={editScenario?.questions}
									onChange={e =>
										updateScenarioField('questions', e.target.value)
									}
									label='The most important questions for training:'
								/>

								<li>
									<RootDesc>
										<b className='block w-[280rem]'>Due Date</b>
									</RootDesc>

									<DropCalendar
										date={editScenario?.created_at}
										setDate={currentDate =>
											updateScenarioField('created_at', currentDate)
										}
									/>
								</li>
							</ul>
						</div>
					)}
				</div>
			</BorderArticle>
		</div>
	)
}
