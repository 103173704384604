export const levelOrder = ['easy', 'medium', 'hard']
export const ROLE_LIST = [
	{ id: 0, name: 'Admin' },
	{ id: 1, name: 'Manager' },
	{ id: 2, name: 'Representative' },
]

export const capitalize = str => {
	return str.charAt(0).toUpperCase() + str.slice(1)
}

export const isEqual = (editObj, currentObj) => {
	return Object.keys(editObj).every(key => editObj[key] === currentObj[key])
}
