import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
	clearViewUser,
	viewUser,
} from '@/redux/slices/userSlices/viewUserSlice'

import { RootDesc } from '@/components/ui/descriptions/RootDesc'
import { ControlButton } from '@/components/ui/buttons/ControlButton'
import { BackButton } from '@/components/ui/buttons/BackButton'
import { Spinner } from '@/components/ui/Spinner'
import UserImage from '@/components/ui/UserImage'

import styles from './styles.module.scss'

const UserField = ({ label, value }) => (
	<li>
		<RootDesc>
			<b className='block min-w-[150rem]'>{label}</b>
		</RootDesc>

		<RootDesc>
			<span>{value}</span>
		</RootDesc>
	</li>
)

export const UserViewLayout = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const { id } = useParams()
	const { user, serverStatus } = useSelector(state => state.viewUser)

	const handleClickEdit = () => {
		navigate(`/admin-panel/users/edit/${id}`, { state: { id } })
	}

	useEffect(() => {
		dispatch(viewUser({ id }))

		return () => {
			dispatch(clearViewUser())
		}
	}, [dispatch, id])

	return (
		<div className={styles.userWrapper}>
			<div className={styles.userHead}>
				<BackButton />
			</div>

			{serverStatus == 'loading' ? (
				<Spinner width={80} height={80} />
			) : (
				<div className={`${styles.user} ${styles.userBig}`}>
					<div className={styles.userInfo}>
						<div className={styles.userName}>
							<h2>{user?.name}</h2>

							<ControlButton
								color={'var(--blue)'}
								txt={'Edit'}
								onClick={handleClickEdit}
							/>
						</div>

						<UserImage photoFile={user?.metadata} user={user} />
					</div>

					<div className={styles.content}>
						<ul>
							<UserField label='Last Name' value={user?.lastname} />

							<UserField label='Email' value={user?.email} />

							<UserField label='Password' value='**********' />

							<UserField label='Default Role' value={user?.role} />

							{user?.role !== 'Admin' && (
								<>
									<UserField label='Product Line' value={user?.product_line} />

									<UserField label='Country' value={user?.country} />
								</>
							)}
						</ul>
					</div>
				</div>
			)}
		</div>
	)
}
