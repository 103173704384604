import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'js-cookie'
import AuthService from '@/services/AuthService'

const initialState = {
	consent: false,
	auth: false,
	id: null,
	email: '',
	name: '',
	lastname: '',
	password: '',
	role: '',
	cover: '',
	error: '',
	serverStatus: '',
}

const UNAUTHORIZED_ERROR = 'Unauthorized!'
const REFRESH_TOKEN_ERROR = 'Failed to get refresh token!'
const DEFAULT_ERROR = 'Error server!'

export const signIn = createAsyncThunk(
	'auth/signIn',
	async ({ email, password }) => {
		try {
			const response = await AuthService.signIn(email, password)

			Cookies.set('refresh', response.data.rt)
			Cookies.set('jwt', response.data.jwt)

			return response.data.user
		} catch (err) {
			throw new Error(UNAUTHORIZED_ERROR)
		}
	}
)

export const signOut = createAsyncThunk('auth/signOut', async () => {
	try {
		const rt = Cookies.get('refresh')

		if (rt) {
			await AuthService.signOut(rt)

			Cookies.remove('refresh')
			Cookies.remove('jwt')
		} else {
			throw new Error(DEFAULT_ERROR)
		}
	} catch (err) {
		throw new Error(UNAUTHORIZED_ERROR)
	}
})

export const checkAuth = createAsyncThunk('auth/checkAuth', async () => {
	try {
		const rt = Cookies.get('refresh')

		if (rt) {
			const response = await axios.post(
				'/jwt',
				{ rt },
				{ withCredentials: true }
			)

			Cookies.set('refresh', response.data.rt)
			Cookies.set('jwt', response.data.jwt)

			return response.data.user
		} else {
			throw new Error(DEFAULT_ERROR)
		}
	} catch (e) {
		throw new Error(REFRESH_TOKEN_ERROR)
	}
})

const candidateSlice = createSlice({
	name: 'auth-candidate',
	initialState,
	reducers: {
		setEmail(state, action) {
			state.email = action.payload
		},
		setPassword(state, action) {
			state.password = action.payload
		},
		toggleConsent(state) {
			state.consent = !state.consent
		},
		setError(state, action) {
			state.error = action.payload
		},
		setServerStatus(state, action) {
			state.serverStatus = action.payload
		},
		clearCandidate() {
			return initialState
		},
	},
	extraReducers: builder => {
		builder
			.addCase(signIn.pending, state => {
				state.serverStatus = 'loading'
			})
			.addCase(signIn.fulfilled, (state, action) => {
				Object.assign(state, {
					auth: true,
					consent: false,
					id: action.payload.id,
					email: action.payload.email,
					password: '',
					name: action.payload.name,
					lastname: action.payload.lastname,
					role: action.payload.type,
					cover: action.payload.cover,
					error: '',
					serverStatus: 'success',
				})
			})
			.addCase(signIn.rejected, state => {
				state.error = UNAUTHORIZED_ERROR
				state.serverStatus = 'error'
			})

			.addCase(signOut.pending, state => {
				state.serverStatus = 'loading'
			})
			.addCase(signOut.fulfilled, state => {
				Object.assign(state, initialState)
			})
			.addCase(signOut.rejected, state => {
				state.error = UNAUTHORIZED_ERROR
				state.serverStatus = 'error'
			})

			.addCase(checkAuth.pending, state => {
				state.serverStatus = 'loading'
			})
			.addCase(checkAuth.fulfilled, (state, action) => {
				Object.assign(state, {
					auth: true,
					consent: false,
					email: action.payload.email,
					id: action.payload.id,
					name: action.payload.name,
					lastname: action.payload.lastname,
					password: '',
					role: action.payload.type,
					cover: action.payload.cover,
					error: '',
					serverStatus: 'success',
				})
			})
			.addCase(checkAuth.rejected, state => {
				state.error = ''
				state.serverStatus = ''
			})
	},
})

export const {
	setEmail,
	setPassword,
	toggleConsent,
	setError,
	setServerStatus,
	clearCandidate,
} = candidateSlice.actions

export default candidateSlice.reducer
