import React, { memo, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import ReactPaginate from 'react-paginate'

import {
	useTable,
	useSortBy,
	usePagination,
	useGlobalFilter,
	useRowSelect,
} from 'react-table'

import { RootDesc } from '@/components/ui/descriptions/RootDesc'
import TableSelect from '@/components/ui/inputs/TableSelect'
import { Spinner } from '@/components/ui/Spinner'
import { WarningData } from '@/components/ui/WarningData'

import sortImage from '@/assets/images/root/table-sort.svg'
import styles from './styles.module.scss'

export const GlobalFilter = memo(({ filter, setFilter }) => {
	const handleChange = e => {
		const value = e.target.value || undefined
		if (value !== filter) {
			setFilter(value)
		}
	}

	return (
		<input
			className={styles.searchInput}
			value={filter || ''}
			onChange={handleChange}
			placeholder='Search Everything (e.g. Name, Email, Role, Country)'
		/>
	)
})

export const TableLayout = props => {
	const { role } = useSelector(state => state.candidate)

	const {
		serverStatus,
		columns,
		data,
		filterCheckbox,
		selectSize,
		toPage,
		dataSize,
		totalPages,
	} = props

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		state,
		setGlobalFilter,
		selectedFlatRows,
		rows,
		prepareRow,
	} = useTable(
		{
			columns,
			data,
		},
		useGlobalFilter,
		useSortBy,
		usePagination,
		useRowSelect
	)

	useEffect(() => {
		const selectedIds = selectedFlatRows.map(row => row.original.id)
		if (selectedIds.length === 1) {
			console.log('Выбрана строка с id:', selectedIds[0])
		} else if (selectedIds.length > 1) {
			console.log('Выбраны строки с id:', selectedIds)
		}
	}, [selectedFlatRows])

	useEffect(() => {
		console.log('Текущий глобальный фильтр:', state.globalFilter)
	}, [state.globalFilter])

	return (
		<div className={styles.tableWrapper}>
			{serverStatus === 'loading' ? (
				<Spinner width={80} height={80} />
			) : data?.length === 0 ? (
				<WarningData type={'empty'} />
			) : !data && serverStatus === 'error' ? (
				<WarningData type={'error'} />
			) : (
				data?.length > 0 && (
					<>
						<div className={styles.tableSettings}>
							{role !== 'Representative' && (
								<GlobalFilter
									filter={state.globalFilter}
									setFilter={setGlobalFilter}
								/>
							)}

							<div className={styles.filterCheckbox}>
								{filterCheckbox && filterCheckbox}
							</div>

							<TableSelect
								options={[5, 10, 25, 50, 100]}
								onSelect={selectSize}
								activeOption={dataSize}
							/>
						</div>

						<table className={styles.table} {...getTableProps()}>
							<thead>
								{headerGroups.map(headerGroup => (
									<tr {...headerGroup.getHeaderGroupProps()} key={uuidv4()}>
										{headerGroup.headers.map(column => (
											<th
												{...column.getHeaderProps(
													column.getSortByToggleProps()
												)}
												key={column.id}
												style={{ width: `${column.width}rem` }}
											>
												<RootDesc>
													<b>
														{column.render('Header')}
														<img src={sortImage} alt='sort' />
													</b>
												</RootDesc>
											</th>
										))}
									</tr>
								))}
							</thead>

							<tbody {...getTableBodyProps()}>
								{rows.map(row => {
									prepareRow(row)

									return (
										<tr {...row.getRowProps()} key={row.id}>
											{row.cells.map(cell => (
												<td {...cell.getCellProps()} key={cell.column.id}>
													<RootDesc>
														<span>{cell.render('Cell')}</span>
													</RootDesc>
												</td>
											))}
										</tr>
									)
								})}
							</tbody>
						</table>
					</>
				)
			)}

			{data?.length !== 0 && (
				<ReactPaginate
					containerClassName={styles.tableControls}
					pageLinkClassName={styles.pageLink}
					activeClassName={styles.activePage}
					previousClassName={styles.previousPage}
					nextClassName={styles.nextPage}
					disabledClassName={styles.disabledButton}
					renderOnZeroPageCount={null}
					pageRangeDisplayed={3}
					pageCount={totalPages}
					onPageChange={item => toPage(item.selected)}
					breakLabel='...'
					nextLabel={
						<button>
							<RootDesc>
								<span>Next</span>
							</RootDesc>

							<i
								style={{
									borderTop: '5rem solid transparent',
									borderBottom: '5rem solid transparent',
									borderLeft: '9rem solid var(--blueLight)',
								}}
							></i>
						</button>
					}
					previousLabel={
						<button>
							<i
								style={{
									borderTop: '5rem solid transparent',
									borderBottom: '5rem solid transparent',
									borderRight: '9rem solid var(--blueLight)',
								}}
							></i>

							<RootDesc>
								<span>Previous</span>
							</RootDesc>
						</button>
					}
				/>
			)}
		</div>
	)
}

export default TableLayout
