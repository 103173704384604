import React, { useMemo } from 'react'
import { v4 as uuidv4 } from 'uuid'

import styles from './styles.module.scss'

export const MaskList = ({ onClickCover }) => {
	const coverList = useMemo(
		() => [
			{ id: uuidv4(), name: 'peachGr' },
			{ id: uuidv4(), name: 'violetGr' },
			{ id: uuidv4(), name: 'pinkGr' },
		],
		[]
	)

	return (
		<ul className={styles.maskList}>
			{coverList.map(item => (
				<li
					onClick={() => onClickCover(item.name)}
					style={{ background: `var(--${item.name})` }}
					key={item.id}
				></li>
			))}
		</ul>
	)
}
