import React from 'react'
import styles from './styles.module.scss'
import { BorderArticle } from '@/components/ui/BorderArticle'
import { RootDesc } from '@/components/ui/descriptions/RootDesc'

export const StatisticCardsLayout = ({ list }) => {
	return (
		<div className={styles.overviewList}>
			{list &&
				list.map(item => (
					<div key={item.id}>
						<BorderArticle>
							<div className={styles.overviewItem}>
								<img src={item.icon} alt='icon' />

								<div className={styles.overviewItemContent}>
									<RootDesc>
										<b className='font-[600] text-[var(--grey)]'>
											{item.title}
										</b>
									</RootDesc>

									<div className={styles.overviewItemBottom}>
										<strong>{item.total}</strong>

										<RootDesc>
											<b
												style={
													item.totalChange.includes('+')
														? { color: 'var(--green)' }
														: { color: 'var(--red)' }
												}
												className='font-[23rem]'
											>
												{item.totalChange}
											</b>
											<br />
											<span className='text-[var(--grey)]'>from last week</span>
										</RootDesc>
									</div>
								</div>
							</div>
						</BorderArticle>
					</div>
				))}
		</div>
	)
}
