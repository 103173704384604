import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { BorderArticle } from '@/components/ui/BorderArticle'
import styles from './styles.module.scss'
import { RootIcon } from '@/components/ui/icons/RootIcon'
import { RootDesc } from '@/components/ui/descriptions/RootDesc'
import { v4 as uuidv4 } from 'uuid'
import { ControlButton } from '@/components/ui/buttons/ControlButton'
import { useDispatch, useSelector } from 'react-redux'
import { EditInput } from '@/components/ui/inputs/EditInput'
import { SmallDesc } from '@/components/ui/descriptions/SmallDesc'
import cancelImage from '@/assets/images/popups/cancel-upload.svg'
import { usePopup } from '../InfoPopup/PopupContext'
import { UploadImagePopup } from '@/popups/uploadPopups/UploadImagePopup'
import { isEqual } from '@/helpers'
import { setIsScenarioEdit } from '@/redux/slices/scenarioSlices/editScenarioSlice'
import { RootCheckbox } from '@/components/ui/inputs/RootCheckbox'

export const VideoEditLayout = () => {
	const { openPopup } = usePopup()
	const location = useLocation()
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const scenario = location.state?.scenario

	const { editScenario } = useSelector(state => state.editScenario)

	const fieldList = [
		{
			id: uuidv4(),
			type: 'products',
			name: 'Related Products',
		},
		{
			id: uuidv4(),
			type: 'purpose',
			name: 'Purpose of the Call',
		},
		{
			id: uuidv4(),
			type: 'objectives',
			name: 'Objectives of the Call',
		},
		{
			id: uuidv4(),
			type: 'focus',
			name: 'Focus of the Call',
		},
		{
			id: uuidv4(),
			type: 'messages',
			name: 'Key Messages',
		},
		{
			id: uuidv4(),
			type: 'structure',
			name: 'Call Structure',
		},
		{
			id: uuidv4(),
			type: 'script',
			name: 'Call Script',
		},
		{
			id: uuidv4(),
			type: 'documents',
			name: 'Related Documents',
		},
		{
			id: uuidv4(),
			type: 'questions',
			name: 'The most important questions for training:',
		},
		{
			id: uuidv4(),
			type: 'due_date',
			name: 'Due Date',
		},
		{
			id: uuidv4(),
			type: 'status',
			name: 'Status',
		},
	]

	const coverList = [
		{
			id: uuidv4(),
			name: 'peachGr',
		},
		{
			id: uuidv4(),
			name: 'violetGr',
		},
		{
			id: uuidv4(),
			name: 'pinkGr',
		},
	]

	const handleClickCover = color => {
		dispatch(setEditScenario({ ...editScenario, cover: color }))
	}

	const handleClickSelect = (fieldType, selectedOption) => {
		dispatch(setEditScenario({ ...editScenario, [fieldType]: selectedOption }))
	}

	const handleClickUploadImage = () => {
		openPopup(<UploadImagePopup />)
	}

	const handleClickUploadFile = () => {}

	useEffect(() => {
		!isEqual(editScenario, scenario)
			? dispatch(setIsScenarioEdit(true))
			: dispatch(setIsScenarioEdit(false))
	}, [editScenario])

	return (
		<>
			<div className={styles.scenarioWrapper}>
				<BorderArticle>
					<div className={styles.scenario}>
						<div className={styles.scenarioHead}>
							<button onClick={() => navigate(-1)}>
								<RootIcon width={17} height={17} id={'arrow-back'} />

								<RootDesc>
									<span>Back</span>
								</RootDesc>
							</button>
						</div>

						<div className={styles.scenarioContent}>
							<div className={styles.scenarioEditContent}>
								<div className={styles.scenarioContentHead}>
									<div className='flex items-center gap-[16rem]'>
										<EditInput
											width={350}
											label='scenario-name'
											type='text'
											value={editScenario.name}
											onChange={e =>
												dispatch(
													setEditScenario({
														...editScenario,
														name: e.target.value,
													})
												)
											}
										/>

										<ControlButton
											color='var(--green)'
											txt={'Save'}
											onClick={() => alert('save')}
										/>
									</div>

									<div className='flex items-start gap-[16rem]'>
										<RootDesc>
											<b className='block w-[150rem]'>Choose Background</b>
										</RootDesc>

										<ul className={styles.maskList}>
											{coverList.map(item => (
												<li
													onClick={() => handleClickCover(item.name)}
													style={{ background: `var(--${item.name})` }}
													key={item.id}
												></li>
											))}
										</ul>
									</div>

									<div className='flex items-start gap-[16rem]'>
										<RootDesc>
											<b className='block w-[150rem]'>Cover</b>
										</RootDesc>

										<div className='flex flex-col items-start gap-[16rem]'>
											<SmallDesc>
												<span className={styles.plateDocument}>
													Scenario_1_cover.jpg
													<img src={cancelImage} alt='cancel' />
												</span>
											</SmallDesc>

											<ControlButton
												color='var(--violetLight)'
												txt={'Upload Image'}
												onClick={() => handleClickUploadImage()}
											/>
										</div>
									</div>
								</div>

								<div className='flex flex-col gap-[24rem] items-start'>
									<RootDesc>
										<b>Cover Preview</b>
									</RootDesc>

									<BorderArticle>
										<div className={styles.scenarioPreview}>
											<div
												style={
													editScenario.cover
														? { background: `var(--${editScenario.cover})` }
														: {}
												}
												className={styles.scenarioCover}
											></div>
											<img
												style={
													!editScenario.preview
														? { background: 'var(--greyLight)' }
														: {}
												}
												src={editScenario.preview}
												alt='preview'
											/>
										</div>
									</BorderArticle>
								</div>
							</div>

							<ul
								className={styles.scenarioContentBody + ' ' + styles.editBody}
							>
								{fieldList.map(field => (
									<li key={field.id}>
										<RootDesc>
											<b className='block w-[280rem]'>{field.name}</b>
										</RootDesc>

										{field.type === 'products' ? (
											<RootDesc>
												<span>{editScenario[field.type].join(', ')}</span>
											</RootDesc>
										) : field.type === 'documents' ? (
											<div className={styles.scenarioDocuments}>
												<div className='flex gap-[16rem] items-center'>
													{editScenario[field.type].map(item => (
														<div key={uuidv4()}>
															<SmallDesc>
																<span className={styles.plateDocument}>
																	{item}
																	<img src={cancelImage} alt='cancel' />
																</span>
															</SmallDesc>
														</div>
													))}
												</div>

												<ControlButton
													color='var(--violetLight)'
													txt={'Upload ppt, html, word, pdf'}
													onClick={() => handleClickUploadFile()}
												/>
											</div>
										) : field.type === 'status' ? (
											<div className={styles.scenarioCheckbox}>
												<RootCheckbox
													fontSize={18}
													checked={editScenario[field.type]}
													onChange={e =>
														dispatch(
															setEditScenario({
																...editScenario,
																[field.type]: e.target.checked,
															})
														)
													}
													label='Deactivate'
												/>
											</div>
										) : (
											<EditInput
												label={`scenario-${field.type}`}
												type='text'
												value={editScenario[field.type]}
												onChange={e =>
													dispatch(
														setEditScenario({
															...editScenario,
															[field.type]: e.target.value,
														})
													)
												}
											/>
										)}
									</li>
								))}
							</ul>
						</div>
					</div>
				</BorderArticle>
			</div>
		</>
	)
}
