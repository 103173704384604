import React from 'react'
import styles from './styles.module.scss'
import { BorderArticle } from '@/components/ui/BorderArticle'
import { H3 } from '@/components/ui/titles/H3'
import { VideosCardLayout } from '../VideosCardLayout'
import { RootCheckbox } from '@/components/ui/inputs/RootCheckbox'

export const VideosLayout = ({ videos }) => {
	return (
		<div className='container-big phone:p-0'>
			<div className={styles.videosWrapper}>
				<BorderArticle>
					<div className={styles.videos}>
						<div className={styles.videosHead}>
							<H3>Show Videos</H3>

							<ul>
								<li>
									<RootCheckbox
										onChange={() => alert('all')}
										label={'Show All'}
									/>
								</li>

								<li>
									<RootCheckbox
										onChange={() => alert('Cardiology')}
										label={'Cardiology'}
									/>
								</li>

								<li>
									<RootCheckbox
										onChange={() => alert('Rare Desease')}
										label={'Rare Desease'}
									/>
								</li>

								<li>
									<RootCheckbox
										onChange={() => alert('Allergies')}
										label={'Allergies'}
									/>
								</li>
							</ul>
						</div>

						<ul className={styles.videosList}>
							{videos &&
								videos.map(item => (
									<li key={item.id}>
										<VideosCardLayout video={item} />
									</li>
								))}
						</ul>
					</div>
				</BorderArticle>
			</div>
		</div>
	)
}
