import moment from 'moment'

import RootSelect from '@/components/ui/inputs/RootSelect'
import { H3 } from '@/components/ui/titles/H3'

import chartImage from '@/assets/images/pages/dashboard/chart.png'
import styles from './Calls.module.scss'

export const Calls = () => {
	const handleSelect = option => {
		console.log(option.name)
	}

	const currentYear = moment().year()
	const currentMonth = moment().month()
	const monthsArray = []

	for (let month = 0; month <= currentMonth; month++) {
		const startDate = moment()
			.year(currentYear)
			.month(month)
			.startOf('month')
			.format('YYYY-MM-DD')

		const endDate = moment()
			.year(currentYear)
			.month(month)
			.endOf('month')
			.format('YYYY-MM-DD')

		const monthName = moment().year(currentYear).month(month).format('MMMM')

		monthsArray.push({
			id: month,
			start_date: startDate,
			end_date: endDate,
			name: `${monthName} ${currentYear}`,
		})
	}

	return (
		<div className={styles.calls}>
			<div className={styles.callsHeader}>
				<H3>Calls</H3>

				<RootSelect
					options={monthsArray}
					onSelect={handleSelect}
					placeholder='August 2024'
				/>
			</div>

			<div className={styles.callsChart}>
				<img src={chartImage} alt='chart' />
			</div>
		</div>
	)
}
