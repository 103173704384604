import React, { useState, useEffect, useRef } from 'react'
import styles from './styles.module.scss'
import { RootDesc } from '@/components/ui/descriptions/RootDesc'
import { useSelector } from 'react-redux'

export const RootTextarea = props => {
	const {
		width,
		label,
		type,
		value,
		required,
		onChange,
		disabled,
		placeholder,
	} = props

	const { isMobile } = useSelector(state => state.settings)
	const [textareaHeight, setTextareaHeight] = useState('32rem')
	const textareaRef = useRef(null)

	const handleChange = event => {
		onChange(event)
		setTextareaHeight('auto')
		setTextareaHeight(`${textareaRef.current.scrollHeight}rem`)
	}

	useEffect(() => {
		setTextareaHeight(
			value ? `${textareaRef.current.scrollHeight}rem` : '32rem'
		)
	}, [value])

	return (
		<label className={styles.rootInput}>
			{label && (
				<RootDesc>
					<b className='phone:block phone:w-[100%]'>{label}</b>
				</RootDesc>
			)}

			<textarea
				ref={textareaRef}
				placeholder={placeholder}
				type={type}
				value={value}
				onChange={handleChange}
				aria-label={label}
				required={required}
				disabled={disabled}
				style={{
					width: isMobile ? '100%' : `${width}rem`,
					height: textareaHeight,
					maxHeight: '100rem',
					overflowY: 'scroll',
				}}
			/>
		</label>
	)
}
