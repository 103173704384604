import { usePopup } from '@/components/layouts/InfoPopup/PopupContext'
import { capitalize } from '@/helpers'

import { H3 } from '@/components/ui/titles/H3'
import { RootDesc } from '@/components/ui/descriptions/RootDesc'
import { DropZone } from '@/components/ui/DropZone'

import styles from './styles.module.scss'

const UploadSection = ({ title, description, accept, onUpload, maxFiles }) => (
	<div className={styles.upload}>
		<H3>{title}</H3>

		<div className={styles.uploadDesc}>
			<RootDesc>
				<span>{description}</span>
			</RootDesc>
		</div>

		<DropZone
			maxFiles={maxFiles}
			accept={accept}
			onUpload={onUpload}
			size={50000000}
			type={'.txt'}
		/>
	</div>
)

export const UploadFilePopup = ({ level, fileList, setFileList }) => {
	const { closePopup } = usePopup()

	const handleUpload = files => {
		let updatedFileList = []

		if (!files || files.length === 0) {
			console.log('Files is undefined!')

			return
		}

		if (level) {
			updatedFileList = [...fileList, { level, file: files }]
		} else {
			updatedFileList = [...fileList, { file: files }]
		}

		setFileList(updatedFileList)
		closePopup()
	}

	const uploadConfig = {
		accept: {
			'text/plain': ['.txt'],
		},
		onUpload: handleUpload,
	}

	if (level) {
		return (
			<UploadSection
				title={`Upload a Document for ${capitalize(level)} Level`}
				description='Please select and upload a document for use in product training.'
				{...uploadConfig}
				maxFiles={1}
			/>
		)
	} else {
		return (
			<UploadSection
				title='Upload Scenario-Related Documents'
				description='Please select and upload the files required for this scenario.'
				{...uploadConfig}
				maxFiles={0}
			/>
		)
	}
}
