import React, { useMemo } from 'react'
import styles from './styles.module.scss'
import { v4 as uuidv4 } from 'uuid'
import { VideosLayout } from '@/components/layouts/VideosLayout'

export const Library = () => {
	const videos = useMemo(
		() => [
			{
				id: uuidv4(),
				title: 'Day in the life: Cardiologist_1',
				description:
					"There's never a dull moment for our doctors. Their days are constantly busy and varied – you never know what's around the corner next!",
				category: 'Cardiology',
				time: '20 minutes',
				image: '',
				link: '',
			},
			{
				id: uuidv4(),
				title: 'Day in the life: Cardiologist_2',
				description:
					"There's never a dull moment for our doctors. Their days are constantly busy and varied – you never know what's around the corner next!",
				category: 'Cardiology',
				time: '20 minutes',
				image: '',
				link: '',
			},
			{
				id: uuidv4(),
				title: 'Day in the life: Cardiologist_3',
				description:
					"There's never a dull moment for our doctors. Their days are constantly busy and varied – you never know what's around the corner next!",
				category: 'Allergies',
				time: '20 minutes',
				image: '',
				link: '',
			},
			{
				id: uuidv4(),
				title: 'Day in the life: Cardiologist_4',
				description:
					"There's never a dull moment for our doctors. Their days are constantly busy and varied – you never know what's around the corner next!",
				category: 'Rare Desease',
				time: '20 minutes',
				image: '',
				link: '',
			},
			{
				id: uuidv4(),
				title: 'Day in the life: Cardiologist_5',
				description:
					"There's never a dull moment for our doctors. Their days are constantly busy and varied – you never know what's around the corner next!",
				category: 'Allergies',
				time: '20 minutes',
				image: '',
				link: '',
			},
		],
		[]
	)

	return (
		<div className='container-big'>
			<div className={styles.library}>
				<VideosLayout videos={videos} />
			</div>
		</div>
	)
}
