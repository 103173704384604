import { useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import {
	clearSessions,
	getSessions,
	setSize,
	setPage,
	setSort,
	setTotalPages,
} from '@/redux/slices/sessionSlices/sessionsSlice'

import moment from 'moment'

import TableLayout from '@/components/layouts/TableLayout'
import { ScreenLayout } from '@/components/layouts/ScreenLayout'
import { ControlButton } from '@/components/ui/buttons/ControlButton'
import { RootCheckbox } from '@/components/ui/inputs/RootCheckbox'
import { TableMobileLayout } from '@/components/layouts/TableMobileLayout'

export const AdminAnalytics = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const { isMobile } = useSelector(state => state.settings)
	const { sessions, page, size, sort, totalPages, serverStatus, error } =
		useSelector(state => state.sessions)

	const goToNextPage = () => {
		if (page < totalPages) {
			dispatch(setPage(page + 1))
		} else return
	}

	const goToPreviousPage = () => {
		if (page > 1) {
			dispatch(setPage(page - 1))
		} else return
	}

	const goToPage = pageIndex => {
		dispatch(setPage(pageIndex + 1))
	}

	const handleSelectSize = option => {
		dispatch(setSize(+option))
		dispatch(setPage(1))
		dispatch(setTotalPages(Math.ceil(totalPages / size)))
	}

	const handleSelectSort = option => {
		dispatch(setSort(option))
	}

	const handleClickView = async session => {
		const id = session.id

		navigate(`view/${id}`, { state: { id } })
	}

	const handleClickDelete = session => {
		console.log(`Delete scenario with id: ${session.id}`)
	}

	const columns = useMemo(
		() => [
			{
				id: 'selection',
				Header: ({ getToggleAllRowsSelectedProps }) => (
					<div>
						<RootCheckbox {...getToggleAllRowsSelectedProps()} />
					</div>
				),
				Cell: ({ row }) => (
					<div>
						<RootCheckbox {...row.getToggleRowSelectedProps()} />
					</div>
				),
				width: 35,
			},
			{ Header: 'Scenario', accessor: 'scenario', width: 225 },
			{ Header: 'User', accessor: 'user', width: 250 },
			{ Header: 'Products', accessor: 'products', width: 250 },
			{ Header: 'Level', accessor: 'level', width: 130 },
			{ Header: 'Status', Cell: () => <>Created</>, width: 140 },
			{
				Header: 'Date',
				accessor: 'date',
				Cell: ({ cell: { value } }) => {
					const formattedDate = moment(value).format('YYYY-MM-DD')

					return <span>{formattedDate}</span>
				},
				width: 245,
			},
			{
				Header: 'Actions',
				Cell: ({ row }) => (
					<div className='flex gap-[8rem]'>
						<ControlButton
							color='var(--orange)'
							txt={'View'}
							onClick={() => handleClickView(row.original)}
						/>
						<ControlButton
							color='var(--red)'
							txt={'Delete'}
							onClick={() => handleClickDelete(row.original)}
						/>
					</div>
				),
				width: 195,
			},
		],
		[]
	)

	useEffect(() => {
		dispatch(getSessions({ page, size }))
	}, [page, size, sort])

	useEffect(() => {
		return () => {
			dispatch(clearSessions())
		}
	}, [])

	return (
		<ScreenLayout switchView={true} title={'Sessions'}>
			{!isMobile ? (
				<TableLayout
					serverStatus={serverStatus}
					serverError={error}
					nextPage={goToNextPage}
					previousPage={goToPreviousPage}
					totalPages={totalPages}
					dataSize={size}
					currentPage={page}
					toPage={goToPage}
					selectSize={handleSelectSize}
					columns={columns}
					data={sessions}
				/>
			) : (
				<TableMobileLayout
					totalPages={totalPages}
					toPage={goToPage}
					columns={columns}
					data={sessions}
					dataSort={sort}
					selectSort={handleSelectSort}
					actions={sessions.map(session => (
						<div key={session.id} className='flex gap-[8rem]'>
							<ControlButton
								color='var(--orange)'
								txt={'View'}
								onClick={() => handleClickView(session)}
							/>
							<ControlButton
								color='var(--red)'
								txt={'Delete'}
								onClick={() => handleClickDelete(session)}
							/>
						</div>
					))}
				/>
			)}
		</ScreenLayout>
	)
}
