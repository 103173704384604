import React, { useState } from 'react'
import Dropzone from 'react-dropzone'

import { RootDesc } from '../descriptions/RootDesc'
import { SmallDesc } from '../descriptions/SmallDesc'
import { ControlButton } from '../buttons/ControlButton'

import uploadImage from '@/assets/images/popups/upload.png'
import styles from './styles.module.scss'

export const DropZone = props => {
	const { maxFiles, multiple, accept, onUpload, size, type } = props

	const [fileInfo, setFileInfo] = useState({
		files: [],
		error: false,
		loading: false,
		progress: 0,
	})

	const onDrop = acceptedFiles => {
		setFileInfo(prev => ({ ...prev, loading: true }))
	}

	const handleDropAccept = dropped => {
		setFileInfo(prev => ({ ...prev, loading: false }))

		const files = dropped.map(file => ({
			name: file.name,
			size: file.size,
		}))

		if (multiple) {
			setFileInfo({
				files,
				error: false,
				progress: 0,
			})

			onUpload(dropped)
		} else {
			const file = dropped[0]

			if (file) {
				setFileInfo({
					files: [{ name: file.name, size: file.size }],
					error: false,
					progress: 0,
				})

				onUpload(file)
			}
		}
	}

	const handleDropReject = () => {
		setFileInfo({ ...fileInfo, error: true })
	}

	return (
		<>
			<Dropzone
				maxFiles={maxFiles || 0}
				multiple={multiple || false}
				accept={accept}
				maxSize={size}
				onDrop={onDrop}
				onDropAccepted={handleDropAccept}
				onDropRejected={handleDropReject}
			>
				{({ getRootProps, getInputProps }) => (
					<div {...getRootProps()} className={styles.dropzoneContainer}>
						<input {...getInputProps()} />

						<div
							className={styles.dropzone}
							style={{
								border: fileInfo.error
									? '2rem dashed var(--red)'
									: '2rem dashed var(--violet)',
							}}
						>
							<div className={styles.dropzoneHead}>
								<RootDesc>
									<b>Drag file to upload</b>
								</RootDesc>

								<img src={uploadImage} alt='upload' />

								<RootDesc>
									<span>or</span>
								</RootDesc>
							</div>

							<ControlButton color='var(--violet)' txt={'Browse Files'} />

							<SmallDesc>
								<span className='inline-block w-[100%] text-center text-[var(--grey)]'>
									Max. file size: <b>5MB</b>
									<br />
									Supported file types: <b>{type}</b>
								</span>
							</SmallDesc>
						</div>

						{fileInfo.error && (
							<SmallDesc>
								<span className='block text-center text-[var(--red)]'>
									File size exceeds the limit!
								</span>
							</SmallDesc>
						)}
					</div>
				)}
			</Dropzone>
		</>
	)
}
