import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
	viewScenario,
	clearViewScenario,
} from '@/redux/slices/scenarioSlices/viewScenarioSlice'

import moment from 'moment'
import { BorderArticle } from '@/components/ui/BorderArticle'
import { RootDesc } from '@/components/ui/descriptions/RootDesc'
import { ControlButton } from '@/components/ui/buttons/ControlButton'
import { SmallDesc } from '@/components/ui/descriptions/SmallDesc'
import { Spinner } from '@/components/ui/Spinner'
import { BackButton } from '@/components/ui/buttons/BackButton'
import { PreviewCard } from '@/components/ui/PreviewCard'

import styles from './styles.module.scss'

const ScenarioField = ({ label, value }) => (
	<li>
		<RootDesc>
			<b className='block w-[280rem]'>{label}</b>
		</RootDesc>

		<RootDesc>
			<span>{value}</span>
		</RootDesc>
	</li>
)

export const ScenarioViewLayout = () => {
	const { scenario, serverStatus } = useSelector(state => state.viewScenario)

	const id = useParams().id
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const handleClickEdit = async id => {
		navigate(`/admin-panel/scenarios/edit/${id}`)
	}

	useEffect(() => {
		dispatch(viewScenario({ id }))

		return () => {
			dispatch(clearViewScenario())
		}
	}, [dispatch])

	return (
		<div className={styles.scenarioWrapper}>
			<BorderArticle>
				<div className={styles.scenario}>
					<BackButton />

					{serverStatus === 'loading' ? (
						<Spinner width={80} height={80} />
					) : (
						<div className={styles.scenarioContent}>
							<div className={styles.scenarioContentHeadWrapper}>
								<div className={styles.scenarioContentHead}>
									<div className='flex items-center gap-[16rem]'>
										<h2>{scenario?.name}</h2>

										<ControlButton
											color='var(--blue)'
											txt={'Edit'}
											onClick={() => handleClickEdit(id)}
										/>
									</div>

									<RootDesc>
										<b>Cover Preview</b>
									</RootDesc>

									<BorderArticle>
										<PreviewCard
											name={scenario?.name}
											mask={scenario?.mask}
											image={scenario?.cover}
										/>
									</BorderArticle>
								</div>
							</div>

							<ul className={styles.scenarioContentBody}>
								<ScenarioField
									label='Related Products'
									value={scenario?.products}
								/>

								<ScenarioField
									label='Objectives of the Call'
									value={scenario?.objectives}
								/>

								<ScenarioField
									label='Focus of the Call'
									value={scenario?.focus}
								/>

								<ScenarioField
									label='Key Messages'
									value={scenario?.key_messages}
								/>

								<ScenarioField
									label='Call Structure'
									value={scenario?.structure}
								/>

								<ScenarioField label='Call Script' value={scenario?.script} />

								<li>
									<RootDesc>
										<b className='block w-[280rem]'>Related Documents</b>
									</RootDesc>

									{scenario?.documents && (
										<SmallDesc>
											<span className={styles.plateDocument}>
												{scenario?.documents}
											</span>
										</SmallDesc>
									)}
								</li>

								<ScenarioField
									label='The most important questions for training:'
									value={scenario?.questions}
								/>

								<ScenarioField
									label='Due Date'
									value={moment(scenario?.created_at).format('DD-MM-YYYY')}
								/>
							</ul>
						</div>
					)}
				</div>
			</BorderArticle>
		</div>
	)
}
