import React, { useState } from 'react'

import { Header } from '@/components/layouts/Header'
import { Controls } from './Controls'
import { Chat } from './Chat'
import { RootButton } from '@/components/ui/buttons/RootButton'
import Avatar from './Avatar'
import AvatarImage from './AvatarImage'

import styles from './styles.module.scss'

export const ChatGame = () => {
	const [showAvatar, setShowAvatar] = useState(false)

	return (
		<div className={styles.gameWrapper}>
			<Header />

			<div className='container-big phone:p-0'>
				<div className={styles.game}>
					<div className={styles.doctor}>
						{window.location.host.includes('test') ||
						window.location.host.includes('localhost') ? (
							<>
								<div className={styles.btn}>
									<RootButton
										onClick={() => setShowAvatar(!showAvatar)}
										txt={'Show/Hide Avatar'}
									/>
								</div>

								{showAvatar ? (
									<>
										<Avatar />
										<Controls />
									</>
								) : (
									<AvatarImage />
								)}
							</>
						) : (
							<>
								<Avatar />
								<Controls />
							</>
						)}
					</div>

					<Chat
						avatar={
							window.location.host.includes('test') ||
							window.location.host.includes('localhost')
								? showAvatar
								: !showAvatar
						}
					/>
				</div>
			</div>
		</div>
	)
}
