import { ScreenLayout } from '@/components/layouts/ScreenLayout'
import { ControlButton } from '@/components/ui/buttons/ControlButton'
import TableLayout from '@/components/layouts/TableLayout'
import { RootCheckbox } from '@/components/ui/inputs/RootCheckbox'
import { useMemo } from 'react'

export const Library = () => {
	const controls = [
		{
			id: 0,
			button: (
				<ControlButton
					color='var(--green)'
					txt={'Add New'}
					onClick={() => alert('Add New')}
				/>
			),
		},
		{
			id: 1,
			button: (
				<ControlButton
					color='var(--red)'
					txt={'Deactivate'}
					onClick={() => alert('Deactivate')}
				/>
			),
		},
	]

	const data = useMemo(
		() => [
			{
				id: 0,
				name: 'Seasonal allergies',
				product: 'Product 4, Product 5',
				line: 'Cardiology',
				created: '2024-05-16',
				inactive: false,
			},
			{
				id: 1,
				name: 'Allergies in infants',
				product: 'Product 4, Product 5',
				line: 'Rare Desease',
				created: '2024-06-26',
				inactive: false,
			},
			{
				id: 2,
				name: 'Seasonal allergies',
				product: 'Product 4, Product 5',
				line: 'Cardiology',
				created: '2024-05-16',
				inactive: false,
			},
			{
				id: 3,
				name: 'Allergies in infants',
				product: 'Product 4, Product 5',
				line: 'Rare Desease',
				created: '2024-06-26',
				inactive: false,
			},
			{
				id: 4,
				name: 'Seasonal allergies',
				product: 'Product 4, Product 5',
				line: 'Cardiology',
				created: '2024-05-16',
				inactive: false,
			},
		],
		[]
	)

	const columns = useMemo(
		() => [
			{
				id: 'selection',
				Header: ({ getToggleAllRowsSelectedProps }) => (
					<div>
						<RootCheckbox {...getToggleAllRowsSelectedProps()} />
					</div>
				),
				Cell: ({ row }) => (
					<div>
						<RootCheckbox {...row.getToggleRowSelectedProps()} />
					</div>
				),
				width: 35,
			},
			{ Header: 'Name', accessor: 'name', width: 235 },
			{ Header: 'Product', accessor: 'product', width: 350 },
			{ Header: 'Product Line', accessor: 'line', width: 340 },
			{ Header: 'Created At', accessor: 'created', width: 225 },
			{
				Header: 'Actions',
				Cell: ({ row }) => (
					<div className='flex gap-[8rem]'>
						<ControlButton
							color='var(--orange)'
							txt={'View'}
							onClick={() => alert(`View ${row.original.id}`)}
						/>
						<ControlButton
							color='var(--blueLight)'
							txt={'Edit'}
							onClick={() => alert(`Edit ${row.original.id}`)}
						/>
						<ControlButton
							color='var(--red)'
							txt={'Deactivate'}
							onClick={() => alert(`Deactivate ${row.original.id}`)}
						/>
					</div>
				),
				width: 285,
			},
		],
		[]
	)

	return (
		<ScreenLayout controlsList={controls} title={'Training Library'}>
			<TableLayout
				columns={columns}
				data={data}
				filterCheckbox={
					<RootCheckbox
						onChange={() => alert('filter-table')}
						label={'Show Inactive Videos'}
					/>
				}
			/>
		</ScreenLayout>
	)
}
