import React from 'react'
import { useSelector } from 'react-redux'

import { capitalize } from '@/helpers'
import { RootDesc } from '@/components/ui/descriptions/RootDesc'
import { H3 } from '@/components/ui/titles/H3'
import { RootButton } from '@/components/ui/buttons/RootButton'
import { RootIcon } from '@/components/ui/icons/RootIcon'

import styles from './styles.module.scss'

export const LevelPopup = props => {
	const { item, close } = props
	const { serverStatus } = useSelector(state => state.avatar)

	const descriptionList = [
		{
			id: 0,
			text: 'In this level, you’ll interact with a virtual doctor who is generally receptive and has a relatively free schedule. Try to convince them to listen to you. You have 10 minutes to present your information and answer any straightforward questions. This level is designed to help you get comfortable with the basic script and interaction protocols.',
			link: '',
		},
		{
			id: 1,
			text: 'In this level, you’ll encounter a virtual doctor who is moderately busy and somewhat hesitant to engage in dialogue. You’ll need to use convincing arguments to get the doctor’s attention. You have 8 minutes to complete the visit. This level aims to improve your persuasive skills and ability to handle detailed product-related queries.',
			link: '',
		},
		{
			id: 2,
			text: 'In this challenging level, the virtual doctor is very busy and highly skeptical. You have only 5 minutes to complete the visit and must be prepared to answer complex questions with in-depth knowledge, including references to scientific studies and clinical trials. This level tests your comprehensive understanding of the product and your persuasive communication skills under high-pressure scenarios.',
			link: '',
		},
	]

	return (
		<div className={styles.levelPopup}>
			<h2>{capitalize(item.name)} Level</h2>

			<H3>Welcome to the Simulation!</H3>

			<RootDesc>
				<span className='block text-center'>
					{descriptionList[item.id].text}
				</span>
			</RootDesc>

			{serverStatus === 'loading' ? (
				<div className={styles.spinner}>
					<RootIcon width={26} height={26} id={'spinner-upload'} />
				</div>
			) : (
				<RootButton txt={'Continue'} type={'button'} onClick={close} />
			)}
		</div>
	)
}
