import React, { useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { BorderArticle } from '@/components/ui/BorderArticle'
import styles from './styles.module.scss'
import { RootIcon } from '@/components/ui/icons/RootIcon'
import { RootDesc } from '@/components/ui/descriptions/RootDesc'
import { v4 as uuidv4 } from 'uuid'
import { ControlButton } from '@/components/ui/buttons/ControlButton'
import { useDispatch, useSelector } from 'react-redux'
import { EditInput } from '@/components/ui/inputs/EditInput'
import { SmallDesc } from '@/components/ui/descriptions/SmallDesc'
import cancelImage from '@/assets/images/popups/cancel-upload.svg'
import EditSelect from '@/components/ui/inputs/EditSelect'
import { usePopup } from '../InfoPopup/PopupContext'
import { UploadImagePopup } from '@/popups/uploadPopups/UploadImagePopup'
import { UploadFilePopup } from '@/popups/uploadPopups/UploadFilePopup'
import { isEqual } from '@/helpers'

import {
	setCreateProduct,
	setIsProductCreate,
	initialCreateProduct,
} from '@/redux/slices/productSlices/createProductSlice'

export const VideoCreateLayout = () => {
	const { openPopup } = usePopup()
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const { createProduct } = useSelector(state => state.createProduct)

	const fieldList = useMemo(() => [
		{
			id: uuidv4(),
			type: 'country',
			name: 'Country',
			value: createProduct.country,
			options: ['Germany', 'Italy', 'Russia', 'Netherland'],
		},
		{
			id: uuidv4(),
			type: 'line',
			name: 'Product Line',
			value: createProduct.line,
			options: ['Cardiology', 'Rare Disease', 'Allergy'],
		},
		{
			id: uuidv4(),
			type: 'documents',
			name: 'Related Documents by Difficulty Level',
			value: createProduct.documents,
		},
		{
			id: uuidv4(),
			type: 'questions',
			name: 'The most important questions for training:',
			value: createProduct.questions,
		},
	])

	const coverList = [
		{
			id: uuidv4(),
			name: 'peachGr',
		},
		{
			id: uuidv4(),
			name: 'violetGr',
		},
		{
			id: uuidv4(),
			name: 'pinkGr',
		},
	]

	const handleClickCover = color => {
		dispatch(setCreateProduct({ ...createProduct, cover: color }))
	}

	const handleClickSelect = (fieldType, selectedOption) => {
		dispatch(
			setCreateProduct({
				...createProduct,
				[fieldType]: selectedOption,
			})
		)
	}

	const handleInputChange = e => {
		// придумать функцию
		console.log(e)
	}

	const handleClickUploadImage = () => {
		openPopup(<UploadImagePopup />)
	}

	const handleClickUploadFile = level => {
		openPopup(<UploadFilePopup title={`Upload a Document for ${level}`} />)
	}

	useEffect(() => {
		!isEqual(createProduct, initialCreateProduct)
			? dispatch(setIsProductCreate(true))
			: dispatch(setIsProductCreate(false))
	}, [createProduct])

	return (
		<>
			<div className={styles.productWrapper}>
				<BorderArticle>
					<div className={styles.product}>
						<div className={styles.productHead}>
							<button onClick={() => navigate(-1)}>
								<RootIcon width={17} height={17} id={'arrow-back'} />

								<RootDesc>
									<span>Back</span>
								</RootDesc>
							</button>
						</div>

						<div className={styles.productContent}>
							<div className={styles.productEditContent}>
								<div className={styles.productContentHead}>
									<div className='flex items-center gap-[16rem]'>
										<EditInput
											width={350}
											label='product-name'
											type='text'
											value={createProduct.name}
											onChange={e =>
												dispatch(
													setCreateProduct({
														...createProduct,
														name: e.target.value,
													})
												)
											}
										/>

										<ControlButton
											color='var(--green)'
											txt={'Save'}
											onClick={() => alert('save')}
										/>
									</div>

									<div className='flex items-start gap-[16rem]'>
										<RootDesc>
											<b className='block w-[150rem]'>Choose Background</b>
										</RootDesc>

										<ul className={styles.maskList}>
											{coverList.map(item => (
												<li
													onClick={() => handleClickCover(item.name)}
													style={{ background: `var(--${item.name})` }}
													key={item.id}
												></li>
											))}
										</ul>
									</div>

									<div className='flex items-start gap-[16rem]'>
										<RootDesc>
											<b className='block w-[150rem]'>Cover</b>
										</RootDesc>

										<div className='flex flex-col items-start gap-[16rem]'>
											<SmallDesc>
												<span className={styles.plateDocument}>
													Scenario_1_cover.jpg
													<img src={cancelImage} alt='cancel' />
												</span>
											</SmallDesc>

											<ControlButton
												color='var(--violetLight)'
												txt={'Upload Image'}
												onClick={() => handleClickUploadImage()}
											/>
										</div>
									</div>
								</div>

								<div className='flex flex-col gap-[24rem] items-start'>
									<RootDesc>
										<b>Cover Preview</b>
									</RootDesc>

									<BorderArticle>
										<div className={styles.productPreview}>
											<div
												style={
													createProduct.cover
														? {
																background: `var(--${createProduct.cover})`,
														  }
														: {}
												}
												className={styles.productCover}
											></div>
											<img
												style={
													!createProduct.preview
														? { background: 'var(--greyLight)' }
														: {}
												}
												src={createProduct.preview}
												alt='preview'
											/>
										</div>
									</BorderArticle>
								</div>
							</div>

							<ul className={styles.productContentBody + ' ' + styles.editBody}>
								{fieldList.map(field => (
									<li key={field.id}>
										<RootDesc>
											<b className='block w-[280rem]'>{field.name}</b>
										</RootDesc>

										{field.type === 'documents' ? (
											<div className={styles.productLevelList}>
												{field.value.map(item => (
													<div key={item.id} className={styles.productLevel}>
														<RootDesc>
															<b className='block w-[150rem]'>{item.name}</b>
														</RootDesc>

														<div className={styles.productDocuments}>
															{item.value.map(val => (
																<SmallDesc>
																	<span className={styles.plateDocument}>
																		{val}
																		<img src={cancelImage} alt='cancel' />
																	</span>
																</SmallDesc>
															))}

															<ControlButton
																color='var(--violetLight)'
																txt={'Upload ppt, html, word, pdf'}
																onClick={() => handleClickUploadFile(item.name)}
															/>
														</div>
													</div>
												))}
											</div>
										) : field.type === 'questions' ? (
											<EditInput
												label='product-name'
												type='text'
												value={field.value.join(', ')}
												onChange={e => handleInputChange(e.target.value)}
											/>
										) : (
											<div className='w-[240rem]'>
												<EditSelect
													activeOption={field.value}
													options={field.options}
													onSelect={selectedOption =>
														handleClickSelect(field.type, selectedOption)
													}
												/>
											</div>
										)}
									</li>
								))}
							</ul>
						</div>
					</div>
				</BorderArticle>
			</div>
		</>
	)
}
